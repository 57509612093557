
import React, { Component } from 'react';
import './assets/animate.css';
import './assets/animate.css';

import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";

import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.jpg";
import logo from "./assets/images/LOGO-white.png";

import calendaricon from "./assets/images/calendar_icon.png";
import calendaricon1 from "./assets/images/calendar_2997687.png";
import calendaricon2 from "./assets/images/clock_7027557.png";
import calendaricon3 from "./assets/images/distribution_13887543.png";
import calendaricon4 from "./assets/images/driver_8601681.png";
import calendaricon5 from "./assets/images/mobile_14241451.png";
import calendaricon6 from "./assets/images/Rectangle (1).png";
import calendaricon7 from "./assets/images/Rectangle (2).png";
import calendaricon8 from "./assets/images/Rectangle.png";
import calendaricon9 from "./assets/images/setting_6691010.png";
import calendaricon10 from "./assets/images/shield_6941222.png";
import calendaricon11 from "./assets/images/time-left_183300.png";


import diffobj from "./assets/images/diffobj.png";


import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";

import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import  MapContainer  from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import IndustryComponent from './IndustriesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import eventEmitter from './Eventemitter';
// import CourierCarousel from './src/CourierCarousel';
import DHL from './assets/images/dhl.svg'
import EVRI from './assets/images/evri.jpg'
import DPD from './assets/images/dpd.png'
import UPS from './assets/images/ups.png'
import FEDEX from './assets/images/fedex.png'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

//function App() {
  class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pickupLocation: '',
        dropOffLocation: '',
        vehicle: '',
        postalCodes: [],
        selectedCollectionCode: "",
        selectedDeliveryCode: "",
        filteredPostalCodes: [],
        filteredPostalDeliveryCodes:[],
        vehicleTypes: [],
        selectedVehicleType: '',
        selectedVehicleName: '',
        isHovered: false,
        returnJourney: false,
        images: [
          //storageBanner,
          //logistics1,
          svgBanner,
          // Add more image URLs if needed
        ],
        currentIndex: 0,
        collectionCodeError: false,
        deliveryCodeError: false,
        vehicleTypeError: false,
        isLoading: false,
        loadingPostalCodes: false,
        emailEst: localStorage.getItem("bookingemail") ? localStorage.getItem("bookingemail") : "",
        emailEstError: false,
        webLoading: true,
        out_of_hours: false,
        out_of_hours_message: "",
        serviceList: [],
        query: '',
        isDropdownOpen: false,
        isDropdownOpenDelivery: false,
        selectedPostalCode: '',
        resultCollectionCode: '',
        resultDeliveryCode: '',
        vehicle_distance_surcharge: '',
        servicePoints: [],
        shippingMethods: [],
        combinedData: [],
        stops: [{ collectionCode: '', deliveryCode: '' }],
        username: localStorage.getItem('username'),
        currentTab: 'sameDay',
        parcels: [{ quantity: '', weight: '', length: '', width: '', height: '', value:'' }],
        error: {},
        fromPostalCode: '',
      toPostalCode: '',
        arePostalCodesValid: { fromPostalCode: false, toPostalCode: false },
        postalCodeErrors: { fromPostalCode: '', toPostalCode: '' },
        countries:[],
        country: "GB",
        //this.vApis = new Apis()
      };

      this.fetchPostalCodes = this.fetchPostalCodes.bind(this);
      this.fetchPostalCodesDelivery = this.fetchPostalCodesDelivery.bind(this);
      this.handleInputCollectionChange = this.handleInputCollectionChange.bind(this);
      this.handleCollectionPostalCodeSelect = this.handleCollectionPostalCodeSelect.bind(this);

      this.handleInputDeliveryChange = this.handleInputDeliveryChange.bind(this);
      this.handleDeliveryPostalCodeSelect = this.handleDeliveryPostalCodeSelect.bind(this);
    }

    
    componentDidMount() {

      
      this.loginListener = (username) => {
        this.setState({ username });
      };
      eventEmitter.on('login', this.loginListener);

      this.loginEmailListener = (loginEmail) => {
        this.setState({ emailEst: loginEmail });
      };
      eventEmitter.on('loginEmail', this.loginEmailListener);
      window.onload = () => {
        // Set isLoading to false once all resources are loaded
       // this.setState({ webLoading: false });

        
      };


      const images1 = document.querySelectorAll('img');
     // console.log("images1----------",images1)
      let loadedCount = 0;
  
      const checkAllImagesLoaded = () => {
        //console.log("checkAllImagesLoaded")
        loadedCount++;
  // console.log("images.length===",images1.length);
  // console.log("loadedCount===",loadedCount)
        if (loadedCount === images1.length) {
         
          //setTimeout(() => {
            this.setState({ webLoading: false });
            //console.log("all loaded");
            const imageContainer = document.querySelector('.van');
            if (imageContainer) {
              imageContainer.classList.add('bannervan');
            }
          //}, 2000);
          
        }
      };
  
      //console.log("images===",images1)
      images1.forEach((image) => {
        //console.log("image==",image)
        if (image.complete) {
          checkAllImagesLoaded();
        } else {
          image.onload = checkAllImagesLoaded;
        }
      });
      //this.getOutHours();
      //this.fetchPostalCodes();
      this.fetchVehicles() 
      this.fetchServices()
      this.fetchCountries()
      // this.fetchServicePoints()
      // this.getShippingPrice()
      //this.fetchShippingMethods()
      //this.getShippingRates()
      //this.fetchIndustries()
      localStorage.setItem("selectedcountry","GB")
      
    }

    fetchCountries = async () => {
      const allCountries = await vApis.fetchData('calculator/countries-iso2/');
      const countries = allCountries?.data?.countries;
      this.setState({ countries });
    }
    handleTabSwitch = (tab) => {
      this.setState({ currentTab: tab });
    }
    // handleParcelChange = (e, index, field) => {
    //   const newParcels = [...this.state.parcels];
    //   newParcels[index][field] = e.target.value;
    //   this.setState({ parcels: newParcels });
    //   localStorage.setItem(field,e.target.value)
    // }
  
    handleCountryChange = (e) => {
      const selectedCountry = e.target.value;
      console.log("selectedCountry===",selectedCountry)
      this.setState({ country: selectedCountry });
      localStorage.setItem('selectedcountry', selectedCountry);
    };

    // handleAddParcel = () => {
    //   this.setState((prevState) => ({
    //     parcels: [...prevState.parcels, { quantity: '', weight: '', length: '', width: '' }]
    //   }));
    // }

    handleParcelChange = (e, index, field) => {
      const newParcels = [...this.state.parcels];
      newParcels[index][field] = e.target.value;
    
      // Update state
      this.setState({ parcels: newParcels });
    
      // Update parcels in localStorage
      localStorage.setItem('parcels', JSON.stringify(newParcels));

      const totalWeight = this.state.parcels.reduce((sum, parcel) => {
        return sum + Number(parcel.weight); // Convert weight to a number and add to the sum
      }, 0);

      const totalParcelValue = this.state.parcels.reduce((sum, parcel) => {
        return sum + Number(parcel.value); // Convert weight to a number and add to the sum
      }, 0);
      
      console.log("Total Weight:", totalWeight);
      localStorage.setItem('weight',totalWeight)

      console.log("Total ParcelValue:", totalParcelValue);
      localStorage.setItem('value',totalParcelValue);

      // Retrieve the parcel data from local storage


      //const parcels = JSON.parse(localStorage.getItem('parcels')) || [];

      // Calculate the total number of parcels based on quantity
      const totalParcels = this.state.parcels.reduce((total, parcel) => {
        return total + (parseInt(parcel.quantity, 10) || 1);
      }, 0);
      
      console.log("Total number of parcels:", totalParcels);
localStorage.setItem('parcelcount',totalParcels);
    };
    
    
    
    handleAddParcel = () => {
      this.setState((prevState) => {
        const updatedParcels = [
          ...prevState.parcels, 
          { name: '', weight: '', length: '', width: '', height: '', value: '', quantity: '' }
        ];
    
        // Update parcels in state
        return { parcels: updatedParcels };
      }, () => {
        // After state is updated, also update localStorage
        localStorage.setItem('parcels', JSON.stringify(this.state.parcels));

        const totalWeight = this.state.parcels.reduce((sum, parcel) => {
          return sum + Number(parcel.weight); // Convert weight to a number and add to the sum
        }, 0);
  
        const totalParcelValue = this.state.parcels.reduce((sum, parcel) => {
          return sum + Number(parcel.value); // Convert weight to a number and add to the sum
        }, 0);
        
        console.log("Total Weight:", totalWeight);
        localStorage.setItem('weight',totalWeight)
  
        console.log("Total ParcelValue:", totalParcelValue);
        localStorage.setItem('value',totalParcelValue);
  
        // Retrieve the parcel data from local storage
  
  
  // Check if parcelData is an array and count the number of objects
  const parcelCount = Array.isArray(this.state.parcels) ? this.state.parcels.length : 0;
  
  console.log("Number of parcels:", parcelCount);
  localStorage.setItem('parcelcount',parcelCount);
      });
    };

    handleRemoveParcel = (index) => {
      this.setState((prevState) => {
        const updatedParcels = prevState.parcels.filter((_, i) => i !== index);
    
        // Update parcels in state and localStorage
        return { parcels: updatedParcels };
      }, () => {
        localStorage.setItem('parcels', JSON.stringify(this.state.parcels));


        const totalWeight = this.state.parcels.reduce((sum, parcel) => {
          return sum + Number(parcel.weight); // Convert weight to a number and add to the sum
        }, 0);
  
        const totalParcelValue = this.state.parcels.reduce((sum, parcel) => {
          return sum + Number(parcel.value); // Convert weight to a number and add to the sum
        }, 0);
        
        console.log("Total Weight:", totalWeight);
        localStorage.setItem('weight',totalWeight)
  
        console.log("Total ParcelValue:", totalParcelValue);
        localStorage.setItem('value',totalParcelValue);
  
        // Retrieve the parcel data from local storage
  
  
  // Check if parcelData is an array and count the number of objects
  const parcelCount = Array.isArray(this.state.parcels) ? this.state.parcels.length : 0;
  
  console.log("Number of parcels:", parcelCount);
  localStorage.setItem('parcelcount',parcelCount);
      });
    };
    
    // Prepare parcel object for booking submission
prepareParcelBookingData = () => {
  const {
    first_name, last_name, company, address, house, city, postcode,
    telephone_number, email, country
  } = this.state; // Assuming these are part of your state

  const shipmentId = Number(localStorage.getItem("selectedShippingId"));
  const servicePoint = localStorage.getItem("servicepoint");
  const parcels = JSON.parse(localStorage.getItem('parcels')) || [];

  const parcelData = {
    first_name: first_name,
    last_name: last_name,
    company_name: company,
    address: address,
    house_number: house,
    city: city,
    postal_code: postcode,
    telephone: telephone_number,
    request_label: true,
    email: email,
    country: country,
    shipment: { id: shipmentId },
    data: {}, // You can fill this based on your data structure
    to_service_point: servicePoint,
    parcels: parcels.map((parcel, index) => ({
      name: parcel.name,
      weight: parcel.weight,
      length: parcel.length,
      width: parcel.width,
      height: parcel.height,
      price: localStorage.getItem(`quoteestimation${index}`), // or you can compute this value dynamically
      quantity: parcel.quantity
    }))
  };

  // Submit parcelData to the server
  console.log('Prepared Parcel Data for Booking:', parcelData);

  return parcelData;
};


    renderSameDayForm() {
      return (
        <form onSubmit={(e) => this.validatePostcode(e, 'calculate-quote')} >
          <h2>Get Quote & Book Online</h2>
  
          {this.state.stops.map((stop, index) => (
            <div key={index}>
              {stop.collectionCode !== undefined && (
                <div className="autocomplete m-0">
                  <label className="label select-label">Collection Postal Code</label>
                  <input
                    type="text"
                    value={stop.collectionCode}
                    onChange={(e) => this.handleInputCollectionChange(e, index)}
                    placeholder="Enter postal code"
                  />
                  {index > 0 && (
                    <button type="button" onClick={() => this.handleDeleteCollection(index)} className="btn btn-danger delete-btn">
                      <i className="fa fa-times"></i>
                    </button>
                  )}
                  <div className='form-group m-0'>
                    <label className="label select-label red">{stop.resultCollectionCode}</label>
                  </div>
                </div>
              )}
              {stop.deliveryCode !== undefined && (
                <div className="autocomplete m-0">
                  <label className="label select-label">Delivery Postal Code</label>
                  <input
                    type="text"
                    value={stop.deliveryCode}
                    onChange={(e) => this.handleInputDeliveryChange(e, index)}
                    placeholder="Enter postal code"
                  />
                  {index > 0 && (
                    <button type="button" onClick={() => this.handleDeleteDelivery(index)} className="btn btn-danger delete-btn">
                      <i className="fa fa-times"></i>
                    </button>
                  )}
                  <div className='form-group m-0'>
                    <label className="label select-label red">{stop.resultDeliveryCode}</label>
                  </div>
                </div>
              )}
            </div>
          ))}
  
          <button type="button" onClick={this.handleAddCollection} className="btn btn-primary addStopBtn add-collection">
            <i className="fa fa-plus"></i> Add Collection
          </button>
          <button type="button" onClick={this.handleAddDelivery} className="btn btn-primary addStopBtn">
            <i className="fa fa-plus"></i> Add Delivery
          </button>
  
          <div className="form-group">
            <label className="label select-label">Vehicle Type</label>
            <Select
              className='vehicles-data'
              value={this.state.selectedVehicleType}
              onChange={this.handleVehicleTypeChange}
              displayEmpty
              error={this.state.vehicleTypeError}
              helperText={this.state.vehicleTypeError && "Vehicle type is required"}
            >
              <MenuItem value="" disabled>Select Vehicle Type</MenuItem>
              {this.state.vehicleTypes?.map(vehicleType => (
                <MenuItem key={vehicleType.id} value={vehicleType.id}>{vehicleType.vehicle_type}</MenuItem>
              ))}
            </Select>
          </div>
  
  
          <div className='form-group'>
            <label className="label select-label">Email</label>
            <TextField
              className='emailEst'
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.emailEst}
              onChange={this.handleEmailEstChange}
              displayEmpty
              error={this.state.emailEstError}
              helperText={this.state.emailEstError && "Email is required"}
            />
          </div>
  
          <div>
            <FormControlLabel
              className='return-journey-check'
              control={
                <Checkbox
                  checked={this.state.returnJourney}
                  onChange={this.handleCheckboxChange}
                  name="returnJourneyCheckbox"
                  color="primary"
                />
              }
              label="Return Journey"
            />
          </div>
  
          <div className="form-group">
            <div className="row">
              {this.state.username !== null && this.state.username !== "" ? (
                <>
                  <div className="col-6">
                    <button type="button" onClick={(e) => this.validatePostcode(e, 'cart')} className="btn btn-secondary py-3 px-4">Add to Cart</button>
                  </div>
                  <div className="col-6">
                    <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4" />
                  </div>
                </>
              ) : (
                <div className="col-12">
                  <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4" />
                </div>
              )}
            </div>
          </div>
        </form>
      );
    }
  


    // validateParcelPostalCode = (postalCode, fieldName) => {
    //   const autocompleteService = new window.google.maps.places.AutocompleteService();
    //   const request = {
    //     input: postalCode,
    //     componentRestrictions: { country: 'GB' },
    //     types: ['(regions)'],
    //   };
    
    //   autocompleteService.getPlacePredictions(request, (predictions, status) => {
    //     if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || !predictions.length) {
    //       this.setState((prevState) => ({
    //         arePostalCodesValid: {
    //           ...prevState.arePostalCodesValid,
    //           [fieldName]: false,
    //         },
    //         postalCodeErrors: {
    //           ...prevState.postalCodeErrors,
    //           [fieldName]: 'Invalid postal code',
    //         }
    //       }));
    //     } else {
    //       this.setState((prevState) => ({
    //         arePostalCodesValid: {
    //           ...prevState.arePostalCodesValid,
    //           [fieldName]: true,
    //         },
    //         postalCodeErrors: {
    //           ...prevState.postalCodeErrors,
    //           [fieldName]: '',
    //         }
    //       }));
    //     }
    //   });
    // }
    
    validateParcelPostalCode= (postalCode, fieldName) => {
      console.log("postalCode===",postalCode)
      console.log("fieldName===",fieldName)
      const { country } = this.state;
      console.log("country====",country)
      return new Promise((resolve, reject) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        const request = {
          input: postalCode,
          componentRestrictions: { country },
          types: ['(regions)'],
        };
    console.log("request to====",request)
        autocompleteService.getPlacePredictions(request, (predictions, status) => {
          console.log("status to = ",status);
          console.log("predictions to = ",predictions)
          if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || !predictions.length) {
            this.setState((prevState) => ({
              arePostalCodesValid: {
                ...prevState.arePostalCodesValid,
                [fieldName]: false,
              },
              postalCodeErrors: {
                ...prevState.postalCodeErrors,
                [fieldName]: 'Invalid postal code',
              }
            }));
            resolve(false);
          } else {
            this.setState((prevState) => ({
              arePostalCodesValid: {
                ...prevState.arePostalCodesValid,
                [fieldName]: true,
              },
              postalCodeErrors: {
                ...prevState.postalCodeErrors,
                [fieldName]: '',
              }
            }));
            resolve(true);
          }


          console.log("arePostalCodesValid to----",this.state.arePostalCodesValid)
          console.log("arePostalCodesValid= to=======",this.state.arePostalCodesValid)
        });
      });
    };
    
    
    validateParcelPostalCodeFrom= (postalCode, fieldName) => {
      console.log("postalCode from===",postalCode)
      console.log("fieldName from===",fieldName)
      const { country } = this.state;
      console.log("country from====",country)
      return new Promise((resolve, reject) => {
        const autocompleteService = new window.google.maps.places.AutocompleteService();
        const request = {
          input: postalCode,
          componentRestrictions: {country:'GB'},
          types: ['(regions)'],
        };
        console.log("request from====",request)
        autocompleteService.getPlacePredictions(request, (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || !predictions.length) {
            console.log("status from = ",status);
          console.log("predictions from = ",predictions)
            this.setState((prevState) => ({
              arePostalCodesValid: {
                ...prevState.arePostalCodesValid,
                [fieldName]: false,
              },
              postalCodeErrors: {
                ...prevState.postalCodeErrors,
                [fieldName]: 'Invalid postal code',
              }
            }));
            resolve(false);
          } else {
            this.setState((prevState) => ({
              arePostalCodesValid: {
                ...prevState.arePostalCodesValid,
                [fieldName]: true,
              },
              postalCodeErrors: {
                ...prevState.postalCodeErrors,
                [fieldName]: '',
              }
            }));
            resolve(true);
          }
          console.log("arePostalCodesValid from----",this.state.arePostalCodesValid)
          console.log("arePostalCodesValid= from=======",this.state.arePostalCodesValid)
        });
      });
    };
  

  
    validateFields = () => {
      const { fromPostalCode, toPostalCode, parcels } = this.state;
      const errors = {};
  
      if (!fromPostalCode) errors.fromPostalCode = 'From Postal Code is required';
      if (!toPostalCode) errors.toPostalCode = 'To Postal Code is required';
  
      parcels.forEach((parcel, index) => {
        if (!parcel.weight) errors[`weight${index}`] = 'Weight is required';
        if (!parcel.length) errors[`length${index}`] = 'Length is required';
        if (!parcel.width) errors[`width${index}`] = 'Width is required';
        if (!parcel.height) errors[`height${index}`] = 'Height is required';
        if (!parcel.value) errors[`value${index}`] = 'Value is required';
        if (!parcel.quantity) errors[`quantity${index}`] = 'Quantity is required';
      });
  
      return errors;
    }
  
    validatePostcodeParcelSubmit = async (e) => {
      e.preventDefault();
    
      // Clear previous errors
      this.setState({
        errors: {},
        postalCodeErrors: {},
      });
    
      const { fromPostalCode, toPostalCode, parcels } = this.state;
      let errors = {};
      let postalCodeErrors = {};
    
      // Validate each field
      if (!fromPostalCode) {
        errors.fromPostalCode = "From postal code is required.";
      }
    
      if (!toPostalCode) {
        errors.toPostalCode = "To postal code is required.";
      }
    
      // Validate parcels
      parcels.forEach((parcel, index) => {
        if (!parcel.weight) {
          errors[`weight${index}`] = "Weight is required.";
        }
        if (!parcel.length) {
          errors[`length${index}`] = "Length is required.";
        }
        if (!parcel.width) {
          errors[`width${index}`] = "Width is required.";
        }
        if (!parcel.height) {
          errors[`height${index}`] = "Height is required.";
        }
        if (!parcel.value) {
          errors[`value${index}`] = "Value is required.";
        }
        if (!parcel.quantity) {
          errors[`quantity${index}`] = "Quantity is required.";
        }
      });
    
      // Perform postal code validation asynchronously
      let fromPostalCodeValid;
      let toPostalCodeValid;
      if(fromPostalCode!=""){
        fromPostalCodeValid = await this.validateParcelPostalCodeFrom(fromPostalCode, 'fromPostalCode');
      }
      if(toPostalCode!=""){
        toPostalCodeValid = await this.validateParcelPostalCode(toPostalCode, 'toPostalCode');
      }
      // const fromPostalCodeValid = await this.validateParcelPostalCode(fromPostalCode, 'fromPostalCode');
      // const toPostalCodeValid = await this.validateParcelPostalCode(toPostalCode, 'toPostalCode');
    console.log("fromPostalCodeValid]]]]",fromPostalCodeValid)
      if (!fromPostalCodeValid) {
        postalCodeErrors.fromPostalCode = "Invalid from postal code.";
      }
    
      if (!toPostalCodeValid) {
        postalCodeErrors.toPostalCode = "Invalid to postal code.";
      }
    
      this.setState({ errors, postalCodeErrors });
    
      // If there are any errors, do not submit the form
      // if (Object.keys(errors).length > 0 || Object.keys(postalCodeErrors).length > 0) {
      //   return;
      // }

      // If validation passes, prepare data and submit
  if (Object.keys(errors).length === 0 && Object.keys(postalCodeErrors).length === 0) {
    // const bookingData = this.prepareParcelBookingData();
    // console.log("bookingData====",bookingData)
    window.location.href = "/couriers"; // Redirect or make an API call
  }
    
      // If validation passes, redirect to the courier page
      //window.location.href="/couriers"
    };
    
    renderNextDayParcelForm() {
      const { fromPostalCode, toPostalCode, parcels, errors, postalCodeErrors } = this.state;
      return (
        <form className='parcel-form' onSubmit={this.validatePostcodeParcelSubmit}>
          <h2>Compare shipping rates & book</h2>
    
          <div className="form-group autocomplete m-0 w-50 pr-2 float-left fromSection">
            <label className="label select-label fromText" style={{ 'width' : '50px' }}>From:</label>
            <label className="label select-label fromCountryVal" style={{ 'font-weight': 'normal', 'text-transform': 'Capitalize' }}>United Kingdom</label>
            <input
              type="text"
              value={fromPostalCode}
              onChange={(e) => {
                this.setState({ fromPostalCode: e.target.value });
                localStorage.setItem("collectionpostcodes", e.target.value);
                this.validateParcelPostalCodeFrom(e.target.value, 'fromPostalCode');
              }}
              placeholder="Enter postal code"
              style={{ borderColor: errors?.fromPostalCode || postalCodeErrors?.fromPostalCode ? 'red' : '' }}
            />
            {errors?.fromPostalCode && <span className="error-message">{errors.fromPostalCode}</span>}
            {postalCodeErrors?.fromPostalCode && (
              <span className="error-message">{postalCodeErrors.fromPostalCode}</span>
            )}
          </div>
    
          <div className="form-group countrysel w-50 float-right">
          <label className="label select-label toParcelLabel">To:</label>
          <select
  value={this.state.country}
  onChange={this.handleCountryChange}
  className="form-control"
>
  <option value="" disabled>Country</option>
  {Object.entries(this.state.countries).map(([code, name]) => (
    <option key={code} value={code}>
      {name}
    </option>
  ))}
</select>
        </div>
          <div className="form-group autocomplete m-0 w-50 float-right">
            {/* <label className="label select-label">To Postal Codes</label> */}
            <input
              type="text"
              value={toPostalCode}
              onChange={(e) => {
                const value = e.target.value;
                this.setState({ toPostalCode: value });
                localStorage.setItem("deliverypostcodes", value);
                this.validateParcelPostalCode(value, 'toPostalCode');
              }}
              placeholder="Enter postal code"
              style={{ borderColor: errors?.toPostalCode || postalCodeErrors?.toPostalCode ? 'red' : '' }}
            />
            {errors?.toPostalCode && <span className="error-message">{errors.toPostalCode}</span>}
            {postalCodeErrors?.toPostalCode && (
              <span className="error-message">{postalCodeErrors.toPostalCode}</span>
            )}
          </div>
          <label className="label select-label">Parcel Details:</label>
          {parcels.map((parcel, index) => (
            <>
            <div key={index} className="parcel-items float-left">
            <label className="label select-label">Parcel {index+1}:</label>

            <div className="form-group autocomplete m-0 w-50 pr-2 float-left">
                <label className="label select-label">Quantity:</label>
                <input
                  type="text"
                  value={parcel.quantity}
                  onChange={(e) => this.handleParcelChange(e, index, 'quantity')}
                  placeholder="Enter quantity"
                  style={{ borderColor: errors?.[`quantity${index}`] ? 'red' : '' }}
                />
                {errors?.[`quantity${index}`] && <span className="error-message">{errors[`quantity${index}`]}</span>}
              </div>

              <div className="form-group autocomplete m-0 w-50 pr-2 float-right">
                <label className="label select-label">Weight (kg):</label>
                <input
                  type="text"
                  value={parcel.weight}
                  onChange={(e) => this.handleParcelChange(e, index, 'weight')}
                  placeholder="Enter weight"
                  style={{ borderColor: errors?.[`weight${index}`] ? 'red' : '' }}
                />
                {errors?.[`weight${index}`] && <span className="error-message">{errors[`weight${index}`]}</span>}
              </div>
    
              <div className="form-group autocomplete m-0  w-50 pr-2 float-left">
                <label className="label select-label">Length (cm)</label>
                <input
                  type="text"
                  value={parcel.length}
                  onChange={(e) => this.handleParcelChange(e, index, 'length')}
                  placeholder="Enter length"
                  style={{ borderColor: errors?.[`length${index}`] ? 'red' : '' }}
                />
                {errors?.[`length${index}`] && <span className="error-message">{errors[`length${index}`]}</span>}
              </div>
    
              <div className="form-group autocomplete m-0  w-50 pr-2 float-right">
                <label className="label select-label">Width (cm)</label>
                <input
                  type="text"
                  value={parcel.width}
                  onChange={(e) => this.handleParcelChange(e, index, 'width')}
                  placeholder="Enter width"
                  style={{ borderColor: errors?.[`width${index}`] ? 'red' : '' }}
                />
                {errors?.[`width${index}`] && <span className="error-message">{errors[`width${index}`]}</span>}
              </div>
    
              <div className="form-group autocomplete m-0  w-50 pr-2 float-left">
                <label className="label select-label">Height (cm)</label>
                <input
                  type="text"
                  value={parcel.height}
                  onChange={(e) => this.handleParcelChange(e, index, 'height')}
                  placeholder="Enter height"
                  style={{ borderColor: errors?.[`height${index}`] ? 'red' : '' }}
                />
                {errors?.[`height${index}`] && <span className="error-message">{errors[`height${index}`]}</span>}
              </div>


              <div className="form-group autocomplete m-0  w-50 pr-2 float-right">
                <label className="label select-label">Parcel Value</label>
                <input
                  type="text"
                  value={parcel.value}
                  onChange={(e) => this.handleParcelChange(e, index, 'value')}
                  placeholder="Enter parcel value"
                  style={{ borderColor: errors?.[`value${index}`] ? 'red' : '' }}
                />
                {errors?.[`value${index}`] && <span className="error-message">{errors[`value${index}`]}</span>}
              </div>

              {/* Delete Button */}
          {index > 0 && (
            <button
              type="button"
              onClick={() => this.handleRemoveParcel(index)}
              className="btn btn-danger deleteParcelBtn float-left"
            >
              <i className="fa fa-trash"></i> Delete Parcel
            </button>
          )}
            </div>
            </>
          ))}
    
          <button type="button" onClick={this.handleAddParcel} className="btn btn-primary addParcelBtn float-left">
            <i className="fa fa-plus"></i> Add Parcel
          </button>
    
          <div className="form-group float-left">
            <button type="submit" className="btn btn-secondary py-3 px-4 mt-8">Quote</button>
          </div>
        </form>
      );
    }
    
  
    renderNextDayDocumentForm() {
      return (
        <form onSubmit={(e) => this.validatePostcode(e, 'calculate-nextday-document')} >
          <h2>Next Day Document Quote</h2>
  
          {/* Similar form structure as Next Day Parcel Form but for documents */}
          {/* Add appropriate fields for documents here */}
  
          <div className="form-group">
            <button type="submit" className="btn btn-secondary py-3 px-4">Quote</button>
          </div>
        </form>
      );
    }
    componentWillUnmount() {
      eventEmitter.removeListener('login', this.loginListener);
      eventEmitter.removeListener('loginEmail', this.loginEmailListener);
      
    } 

    handleAddStop = () => {
      this.setState((prevState) => ({
        stops: [...prevState.stops, { collectionCode: ''}]
      }));
    };

    handleAddDeliveryStop  = () => {
      this.setState((prevState) => ({
        stops: [...prevState.stops, { deliveryCode: '' }]
      }));
    };
//     getShippingRates = async()=> {

//   const url = 'https://panel.sendcloud.sc/api/v2/shipping-rates';

//   const requestBody = {
//     "from": {
//       "country": "NL",
//       "postal_code": "1011AC"
//     },
//     "to": {
//       "country": "GB",
//       "postal_code": "SW1A1AA"
//     },
//     "weight": 2000, // Weight in grams
//     "length": 10, // Length in cm
//     "width": 10, // Width in cm
//     "height": 10 // Height in cm
//   };

//   try {
//     //const response = await vApis.fetchService(`https://servicepoints.sendcloud.sc/api/v2/service-points?country=${COUNTRY_CODE}&postal_code=${POSTAL_CODE}`);
//     const response = await vApis.postService(url,requestBody);
// console.log("post resp===========",response)
//     const data = await response.json();
//     console.log("data.shipping_rates====",data.shipping_rates)
//     return data.shipping_rates;
//   } catch (error) {
//     console.error('Error fetching shipping rates:', error);
//     return [];
//   }
// }

// fetchShippingMethods = async () => {
//   const url = 'https://servicepoints.sendcloud.sc/api/v2/shipping_methods';

//   try {
//     const response = await vApis.fetchService(url);
//     console.log("check shipping methods:",response)
    
//     this.setState({ shippingMethods: response }, this.combineData);
//   } catch (error) {
//     this.setState({ error, loading: false });
//   }
// };

    // fetchServicePoints = async () => {
    //   const COUNTRY_CODE = 'GB';
    //   //const POSTAL_CODE = '5611AG';
    //     const POSTAL_CODE = 'SN5 8UB';
    //   try {
    //     const response = await vApis.fetchService(`https://servicepoints.sendcloud.sc/api/v2/service-points?country=${COUNTRY_CODE}&postal_code=${POSTAL_CODE}`);
    //     //const response = await fetch('https://servicepoints.sendcloud.sc/api/v2/service-points', {
          
  
    //     console.log("check service points reponse:",response)
    //     this.setState({ servicePoints: response }, this.combineData);
        
    //     //this.setState({ servicePoints: response.data });
    //   } catch (error) {
    //     //this.setState({ error: error.message });
    //   }
    // };

    

    // getShippingPrice = async () => {
    //   const { selectedCourier } = this.state;
  
    //   // if (!selectedCourier) {
    //   //   this.setState({ error: 'Please select a courier service' });
    //   //   return;
    //   // }
  
    //   const url = `http://localhost:3000/api/shipping-methods?carrier_code=hermes_gb`;
  
      
  
    //   try {
    //     const response = await vApis.fetchService(url);
    //     console.log("shipping price of one courier----",response)
    //     //this.setState({ shippingPrice: response, error: null });
    //   } catch (error) {
    //     //this.setState({ shippingPrice: null, error: error.message });
    //   }
    // };
    // componentWillUnmount() {
    //   window.removeEventListener('scroll', this.handleScroll);
    // }

    // runCounters=()=> {
    //   const counters = document.querySelectorAll('.number');

    //   counters.forEach(counter => {
    //     const updateCount = () => {
    //       const target = +counter.getAttribute('data-number');
    //       const count = +counter.innerText;
  
    //       const speed = 200; // Change speed as needed
    //       const inc = target / speed;
  
    //       if (count < target) {
    //         counter.innerText =  Math.ceil(count + inc); // Append "+" sign
    //         setTimeout(updateCount, 1);
    //       } else {
    //         counter.innerText =  target; // Append "+" sign
    //       }
    //     };
  
    //     updateCount();
    //   });
    // }
    // handleScroll = () => {
    //   const sections = document.querySelectorAll('.ftco-section');

    //   sections.forEach(section => {
    //     const sectionTop = section.getBoundingClientRect().top;
    //     const windowHeight = window.innerHeight;

    //     if (sectionTop < windowHeight) {
    //       section.classList.add('show');
    //       if (section.id === "section-counter") {
    //         // Run a specific function
    //         this.runCounters();
    //       }

    //     } else {
    //       section.classList.remove('show');
    //     }
    //   });
    // };

    componentWillUnmount() {
      clearInterval(this.slideshowInterval);
    }
    handleCollectionPostalCodeSelect(postalCode) {
      this.setState({
        selectedCollectionCode: postalCode,
        isDropdownOpen: false,
      });

    }

    handleDeliveryPostalCodeSelect(postalCode) {
      this.setState({
        selectedDeliveryCode: postalCode,
        isDropdownOpenDelivery: false,
      });

    }

    
    handleEmailEstChange = (event) => {
      this.setState({ emailEst: event.target.value }); // Update email state
      localStorage.setItem("bookingemail",event.target.value)
    };
    startSlideshow = () => {
      this.slideshowInterval = setInterval(() => {
        this.setState(prevState => ({
          currentIndex: (prevState.currentIndex + 1) % prevState.images.length
        }));
      }, 3000); // Change slide every 3 seconds
    };
    handleMouseEnter = () => {
      this.setState({ isHovered: true });
    };
  
    handleMouseLeave = () => {
      this.setState({ isHovered: false });
    };

    handleCheckboxChange = (event) => {
      this.setState({ returnJourney: event.target.checked });
    };

    fetchVehicles = async () => {
      //console.log("fetchVehicles=====")
        const allVehicles = await vApis.fetchData('calculator/vehicles/');
        //console.log("all vehicles====",allVehicles?.data?.vehicles_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const vehicleTypes = allVehicles?.data?.vehicles_list;
        //console.log("vehicleTypes===",vehicleTypes);
        this.setState({ vehicleTypes })
    };

    fetchServices = async () => {
      //console.log("fetchServices=====")
        const allServices = await vApis.fetchData('calculator/services/');
        //console.log("all allServices====",allServices?.data?.services_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const serviceList = allServices?.data?.services_list;
        //console.log("serviceList===",serviceList);
        this.setState({ serviceList })
    };

    fetchPostalCodes = async (query) => {
      this.setState({ loadingPostalCodes: true });
        // const allpostcodes = await vApis.fetchData('calculator/postcodes/');
        // this.setState({ postalCodes: allpostcodes?.data?.postcodes })
        // this.setState({ loadingPostalCodes: false });
        //const allpostcodes = await vApis.fetchData('calculator/postcodes/');
        const allpostcodes = await vApis.fetchData(`calculator/autocomplete-postcodes/?query=${query}`);
     
        this.setState({
          postalCodes: allpostcodes?.data?.postcodes,
          isDropdownOpen: true,
          loadingPostalCodes: false
        });
      
    };

    fetchPostalCodesDelivery = async (query) => {
      this.setState({ loadingPostalCodes: true });
        // const allpostcodes = await vApis.fetchData('calculator/postcodes/');
        // this.setState({ postalCodes: allpostcodes?.data?.postcodes })
        // this.setState({ loadingPostalCodes: false });
        //const allpostcodes = await vApis.fetchData('calculator/postcodes/');
        const allpostcodes = await vApis.fetchData(`calculator/autocomplete-postcodes/?query=${query}`);
     
        this.setState({
          postalCodes: allpostcodes?.data?.postcodes,
          isDropdownOpenDelivery: true,
          loadingPostalCodes: false
        });
      
    };


    getOutHours = async () => {
      const currentDate = new Date();
      const options = { timeZone: 'Europe/London', hour12: false }; // Set the timezone to UK and 24-hour format

      // Format the time
      const formattedTime = currentDate.toLocaleTimeString('en-GB', options);

      // Get the current date
      const day = ('0' + currentDate.getDate()).slice(-2);
      const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
      const year = currentDate.getFullYear();
      const formattedDate = `${day}/${month}/${year}`;

      // Create the JSON object
      const out_data = JSON.stringify({
        "time": formattedTime,
        "date": formattedDate
      });
      console.log("out_data===",out_data)
      const response = await vApis.postData('calculator/out_of_hours/',out_data);
      
      console.log("response of out of hours===",response?.data?.status);
      this.setState({out_of_hours:response?.data?.status})
      if(response?.data?.status ===true){
        this.setState({ out_of_hours_message: response?.data?.message})
      }
    }
    // fetchPostalCodes = () => {
    //   // Replace 'YOUR_API_ENDPOINT' with your actual API endpoint
    //   fetch('http://127.0.0.1:8000/calculator/postcodes')
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log("data postcodes===",data.data.postcodes)
    //       // Assuming the data returned from API is an array of postal codes
    //       this.setState({ postalCodes: data.data.postcodes });
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching postal codes:', error);
    //     });
    // };

    handleVehicleTypeChange = (event) => {
      console.log("vehicle id===",event);
      console.log("vehicle id===",event.target.value);
      const selectedId = event.target.value;
      const selectedVehicle = this.state.vehicleTypes?.find(vehicle => vehicle.id === selectedId);
    console.log("Selected Vehicle ID:", selectedId);
    console.log("Selected Vehicle Name:", selectedVehicle.vehicle_type);
    //this.setState({ selectedVehicleType: selectedVehicle.vehicle_type });
      this.setState({ selectedVehicleType: selectedId, selectedVehicleName: selectedVehicle.vehicle_type, vehicleTypeError: !event.target.value });
      localStorage.setItem("selectedvehicle",selectedId)
    }

    // handleSubmit = async () => {
    //   const { stops, selectedVehicleName, emailEst, returnJourney } = this.state;
    
    //   let errors = stops.map(stop => ({
    //     collectionCodeError: !stop.collectionCode,
    //     deliveryCodeError: !stop.deliveryCode
    //   }));
    
    //   let vehicleTypeError = !selectedVehicleName;
    //   let emailEstError = !emailEst;
    
    //   if (errors.some(error => error.collectionCodeError || error.deliveryCodeError) || vehicleTypeError || emailEstError) {
    //     this.setState({
    //       stops: this.state.stops.map((stop, index) => ({
    //         ...stop,
    //         collectionCodeError: errors[index].collectionCodeError,
    //         deliveryCodeError: errors[index].deliveryCodeError
    //       })),
    //       vehicleTypeError,
    //       emailEstError
    //     });
    //     return;
    //   }
    
    //   this.setState({ isLoading: true });
    
    //   const postcodes = stops.flatMap(stop => [stop.collectionCode, stop.deliveryCode]);
    //   let est_data = JSON.stringify({
    //     postcodes,
    //     vehicle_type: selectedVehicleName,
    //     return_journey: returnJourney,
    //     email: emailEst,
    //   });
    
    //   const response = await vApis.postData('calculator/calculate-quote/', est_data);
    
    //   console.log("response===", response);
    //   localStorage.setItem("quoteestimation", response?.data.quote);
    //   localStorage.setItem("collectionpostcodes", stops.map(stop => stop.collectionCode).join(', '));
    //   localStorage.setItem("deliverypostcodes", stops.map(stop => stop.deliveryCode).join(', '));
    //   localStorage.setItem("quoteestimation", response?.data.quote);
    //   localStorage.setItem("vat", response?.data.VAT);
    //   localStorage.setItem('extras', JSON.stringify(response?.data?.Extras));
    //   localStorage.setItem('quoteid', JSON.stringify(response?.data.quote_id));
    //   localStorage.setItem('vehicledistancesurcharge', response?.data.vehicle_distance_surcharge);
    //   localStorage.setItem("distance", response?.data.distance);
    
    //   window.location.href = '/booking';
    // };
    
  
    handleCollectionCodes=(event, value)=>{
      console.log("coll code val===:",value)
      this.setState({
        selectedCollectionCode: value,
        collectionCodeError: !value
      });
    }

    handleDeliveryCodes=(event, value)=>{
      console.log("coll code val===:",value)
      this.setState({
        selectedDeliveryCode: value,
        deliveryCodeError: !value
        
      });
    }
    // handleInputChange = (event, value) => {
    // //   const filteredPostalCodes = value
    // //   ? this.state.postalCodes.filter((code) => code.startsWith(value))
    // //   : [];
    // // this.setState({ filteredPostalCodes });

    // const query = event.target.value;
    // this.setState({ query }, () => {
    //   if (query.length > 0) {
    //     this.fetchPostalCodes(query);
    //   } else {
    //     this.setState({ postalCodes: [], isDropdownOpen: false });
    //   }
    // });
      
    // };

    handleInputCollectionChange(event, index) {
      const query = event.target.value;
      this.setState({ selectedCollectionCode:query, isDropdownOpen: false }, () => {
       /*  if (query.length > 0) {
          this.fetchPostalCodes(query);
        } else {
          this.setState({ postalCodes: [], isDropdownOpen: false });
        } */
      });

      const { value } = event.target;
      const stops = [...this.state.stops];
      stops[index].collectionCode = value;
      this.setState({ stops });
    }
  
    handleInputDeliveryChange(event,index) {
      const query1 = event.target.value;
      this.setState({ selectedDeliveryCode:query1, isDropdownOpenDelivery: false }, () => {
        /* if (query1.length > 0) {
          this.fetchPostalCodesDelivery(query1);
        } else {
          this.setState({ postalCodes: [], isDropdownOpenDelivery: false });
        } */
      });
      const { value } = event.target;
      const stops = [...this.state.stops];
      stops[index].deliveryCode = value;
      this.setState({ stops });
    }
    

    loadGoogleMapsAPI = (callback) => {
        const existingScript = document.getElementById('googleMaps');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD7zLwxSWy5EdRoDYWii61pPVJEGRRZZH8&libraries=places`;
            script.id = 'googleMaps';
            document.body.appendChild(script);
            script.onload = () => {
                if (callback) callback();
            };
        } else if (callback) {
            callback();
        }
    };

    // validatePostcode = async (e) => {
    //   e.preventDefault();
    //   this.loadGoogleMapsAPI(() => {
    //     const geocoder = new window.google.maps.Geocoder();
    
    //     const validate = (address) => {
    //       return new Promise((resolve, reject) => {
    //         geocoder.geocode({ 'address': address, 'region': 'uk' }, (results, status) => {
    //           if (status === window.google.maps.GeocoderStatus.OK) {
    //             const isInUK = results[0].formatted_address.includes("UK");
    //             if (isInUK) {
    //               resolve("Valid postcode");
    //             } else {
    //               resolve("Invalid postcode.");
    //             }
    //           } else {
    //             resolve("Invalid postcode.");
    //           }
    //         });
    //       });
    //     };
    
    //     const validationPromises = this.state.stops.map((stop) => {
    //       return Promise.all([
    //         validate(stop.collectionCode),
    //         validate(stop.deliveryCode)
    //       ]);
    //     });
    
    //     Promise.all(validationPromises).then((results) => {
    //       const updatedStops = this.state.stops.map((stop, index) => ({
    //         ...stop,
    //         resultCollectionCode: results[index][0],
    //         resultDeliveryCode: results[index][1],
    //       }));
    
    //       this.setState({ stops: updatedStops }, () => {
    //         const allValid = updatedStops.every((stop) => 
    //           stop.resultCollectionCode === "Valid postcode" && 
    //           stop.resultDeliveryCode === "Valid postcode"
    //         );
    
    //         if (allValid) {
    //           this.handleSubmit();
    //         }
    //       });
    //     });
    //   });
    // };
    
    // handleInputDeliveryChange = (event, value) => {
    //   const filteredPostalDeliveryCodes = value
    //   ? this.state.postalCodes.filter((code) => code.startsWith(value))
    //   : [];
    // this.setState({ filteredPostalDeliveryCodes });
      
    // };

    handleAddCollection = () => {
      this.setState((prevState) => ({
        stops: [...prevState.stops, { collectionCode: '' }]
      }));
    };
    
    handleAddDelivery = () => {
      this.setState((prevState) => ({
        stops: [...prevState.stops, { deliveryCode: '' }]
      }));
    };
    
    validatePostcode = async (e,apiEndpoint) => {
      e.preventDefault();
      console.log("validatePostcode")
      this.loadGoogleMapsAPI(() => {
        const geocoder = new window.google.maps.Geocoder();
    
        const validate = (address) => {
          return new Promise((resolve, reject) => {
            geocoder.geocode({ 'address': address, 'region': 'uk' }, (results, status) => {
              if (status === window.google.maps.GeocoderStatus.OK) {
                const isInUK = results[0].formatted_address.includes("UK");
                if (isInUK) {
                  resolve("Valid postcode");
                } else {
                  resolve("Invalid postcode.");
                }
              } else {
                resolve("Invalid postcode.");
              }
            });
          });
        };
    
        const validationPromises = this.state.stops.map((stop) => {
          return Promise.all([
            stop.collectionCode ? validate(stop.collectionCode) : Promise.resolve(''),
            stop.deliveryCode ? validate(stop.deliveryCode) : Promise.resolve('')
          ]);
        });
    
        Promise.all(validationPromises).then((results) => {
          console.log("resilts===",results)
          const updatedStops = this.state.stops.map((stop, index) => ({
            ...stop,
            resultCollectionCode: results[index][0],
            resultDeliveryCode: results[index][1],
          }));
    console.log("updatedStops===",updatedStops)
          this.setState({ stops: updatedStops }, () => {
            const allValid = updatedStops.every((stop) => 
              (!stop.collectionCode || stop.resultCollectionCode === "Valid postcode") && 
              (!stop.deliveryCode || stop.resultDeliveryCode === "Valid postcode")
            );
    console.log("allValid===",allValid)
            if (allValid) {
              if(apiEndpoint==="calculate-nextday-parcel"){
                window.location.href="/couriers"
              }
              else{
                this.handleSubmit(apiEndpoint);
              }

              
            }
          });
        });
      });
    };

    handleAddToCart = async () => {

    }
    
    handleSubmit = async (apiEndpoint) => {
      console.log("handleSubmit apiEndpoint====", apiEndpoint)
      const { stops, selectedVehicleName, emailEst, returnJourney } = this.state;
    
      let errors = stops.map(stop => ({
        collectionCodeError: !stop.collectionCode,
        deliveryCodeError: !stop.deliveryCode
      }));
      console.log("errors===", errors)
      let vehicleTypeError = !selectedVehicleName;
      let emailEstError = !emailEst;
    
      // if (errors.some(error => error.collectionCodeError || error.deliveryCodeError) || vehicleTypeError || emailEstError) {
      //   this.setState({
      //     stops: this.state.stops.map((stop, index) => ({
      //       ...stop,
      //       collectionCodeError: errors[index].collectionCodeError,
      //       deliveryCodeError: errors[index].deliveryCodeError
      //     })),
      //     vehicleTypeError,
      //     emailEstError
      //   });
      //   return;
      // }
      console.log("checking----------------------------------------")
      this.setState({ isLoading: true });
    
      const postcodesseq = this.state.stops.flatMap(stop => [
        stop.collectionCode ? { postcode: stop.collectionCode, stoptype: 'collection' } : null,
        stop.deliveryCode ? { postcode: stop.deliveryCode, stoptype: 'delivery' } : null
      ]).filter(Boolean);
      console.log("postcodesseq====", JSON.stringify(postcodesseq));
      localStorage.setItem("allstops", JSON.stringify(postcodesseq));
      
      const postcodesUp = this.state.stops.flatMap(stop => [
        stop.collectionCode,
        stop.deliveryCode
      ]).filter(Boolean);
      console.log("postcodesUp====", postcodesUp)
    
      console.log("postcodesUp1====", postcodesseq.map(stop => stop.postcode).join(', '))
      let postCodesJson = JSON.stringify(postcodesseq.map(stop => stop))
      localStorage.setItem("postcodestest", postCodesJson);
      console.log("postcodesUp2====", postcodesseq.map(stop => stop))
      
      let est_data = JSON.stringify({
        postcodes: postcodesUp,
        vehicle_type: selectedVehicleName,
        return_journey: returnJourney,
        email: emailEst,
      });
      console.log("est_data===", est_data)
      
      // Modify postcodes before sending to the API
      const postcodesForApi = this.state.stops.flatMap(stop => [
        stop.collectionCode ? "c_" + stop.collectionCode : null,
        stop.deliveryCode ? "d_" + stop.deliveryCode : null
      ]).filter(Boolean);
      
      let api_data = JSON.stringify({
        postcodes: postcodesForApi,
        vehicle_type: selectedVehicleName,
        return_journey: returnJourney,
        email: emailEst,
      });
      console.log("api_data===", api_data)
    
      const response = await vApis.postData(`calculator/calculate-quote/`, api_data);
      console.log("response check==================", response);
    if(response?.data?.status){
      console.log("go to booking");
      localStorage.setItem('quoteid', JSON.stringify(response?.data.quote_id));
      if (apiEndpoint == "calculate-quote") {
        localStorage.setItem("collectionpostcodes", stops.map(stop => stop.collectionCode).join(', '));
        localStorage.setItem("deliverypostcodes", stops.map(stop => stop.deliveryCode).join(', '));
        localStorage.setItem("quoteestimation", response?.data.quote);
        localStorage.setItem("vat", response?.data.Extras.VAT);
        localStorage.setItem('extras', JSON.stringify(response?.data?.Extras));
        localStorage.setItem('vehicledistancesurcharge', response?.data.vehicle_distance_surcharge);
        localStorage.setItem("distance", response?.data.distance);
        window.location.href = '/booking'
      } else if (apiEndpoint === "cart") {
        console.log("go to cart");
        let quoteID = JSON.stringify({
          quote_id: response?.data.quote_id,
        });
        console.log("quoteID===",quoteID)
        const responseCart = await vApis.postData('payments/cart/', quoteID);
        console.log("responseCart===",responseCart)
        if (responseCart?.data.status) {
          window.location.href = '/carts'
        }
      }
      else if(apiEndpoint == "calculate-nextday-parcel"){
       // localStorage.setItem("weight",1)
       window.location.href="/couriers"
      }
    }

    }
    
    

    handleDeleteCollection = (index) => {
      this.setState((prevState) => {
        const stops = [...prevState.stops];
        stops.splice(index, 1);
        return { stops };
      });
    };
    
    handleDeleteDelivery = (index) => {
      this.setState((prevState) => {
        const stops = [...prevState.stops];
        stops.splice(index, 1);
        return { stops };
      });
    };
    
    
    render() {
      const { pickupLocation, dropOffLocation, vehicle } = this.state;
      const { isHovered } = this.state;
      const { returnJourney } = this.state;
      const { images, currentIndex } = this.state;
      const {selectedDeliveryCode, selectedCollectionCode, selectedPostalCode , postalCodes, collectionCodeError, deliveryCodeError, vehicleTypeError, isLoading, loadingPostalCodes, webLoading,isDropdownOpenDelivery, isDropdownOpen   } = this.state;
      const username = localStorage.getItem('username');

      const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5,
          //slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          //slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
         // slidesToSlide: 1 // optional, default to 1.
        }
      };
  return (
    <div className="App">
    {webLoading && 
          
           
      <div className='webloader-overlay'>
        <div className="webloader"></div>
      </div>
    }
    <>
     <Header/>
     <ul>
          {this.state.combinedData.map(point => (
            <li key={point.id}>
              <h2>{point.name}</h2>
              <p>{point.street} {point.house_number}, {point.city}, {point.postal_code}</p>
              <h3>Available Shipping Methods:</h3>
              <ul>
                {point.shippingMethods.map(method => (
                  <li key={method.id}>
                    {method.name} - {method.price.amount / 100} {method.price.currency}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
     {isLoading &&
     <div className="loader-overlay">
     <CircularProgress className="loader" />
     </div>
     }
     {/* <div className="loader-overlay">
      <div className="loader"></div>
    </div> */}
    
    
     <div className="hero-wrap ftco-degree-bg  slider-banner">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slideb ${index === currentIndex ? 'active' : ''}`}
          >
            <div
              className="slide-image"
              style={{
                backgroundImage: `url(${image})`
              }}
            >
              <div class="animation-container">
                <div id="bannervan" className='van'>
                  <img id="vanStyle" src={bannervan} alt=""/>
                  {/* <img id="logoOnVan" src={logo} alt=""/> */}
                </div>
                
                </div>
                <div id="sales-boy">
                  <img src={boy} alt=""/>
                </div>
            </div>
           
          </div>
        ))}
     

    
    
        {/* <div className="overlay"></div> */}
      <div className="container">
        <div className="row no-gutters slider-text">
          <div className="col-lg-7 ftco-animate the-main-text mob-style">
          	{/* <div className="text w-100 text-center mb-md-5 pb-md-5 the-main-cont">
	            <h1 className="mb-4">UK & EU Same Day<br/>Courier Specialists</h1>
	            <p style={{fontSize: "18px"}}>A small river named Duden flows by their place and <br/>supplies it with the necessary regelialia. It is a paradisematic<br/>country, in which roasted parts.</p>
	           
            </div> */}
          </div>
          <div className="col-lg-5 ftco-animate the-main-text">
          	<div className="text w-100 text-center mb-md-5 pb-md-5 the-main-cont">
	            <h1 className="mb-4">Delivering Your Promises, Faster.</h1>
	            <p style={{fontSize: "18px"}}>Experience precision, speed, and reliability with Parcel Lodge—where your package is our priority.


</p>
	           
            </div>
          </div>
		  
		  {/* <div className="price-calc">
	  						<form action="#" className="request-form ftco-animate bg-primary">
		          		<h2>Get an Estimate</h2>
			    				<div className="form-group">
			    					<label className="label">Pick-up location</label>
			    					<input type="text" className="form-control" placeholder="City, Airport, Station, etc"/>
			    				</div>
			    				<div className="form-group">
			    					<label className="label">Drop-off location</label>
			    					<input type="text" className="form-control" placeholder="City, Airport, Station, etc"/>
			    				</div>
			    				
		              <div className="form-group">
		                <label  className="label">Vehicle</label>
		                <input type="text" className="form-control" id="time_pick_1" placeholder="Vehicle"/>
		              </div>
			            <div className="form-group">
			              <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4"/>
			            </div>
			    			</form>
	  					</div> */}


        </div>
      </div>
      </div>
    {/* <div className="hero-wrap ftco-degree-bg" style={{
        backgroundImage: `url(${sidebarBGImage})`
      }}>

    
    
      
    </div> */}

    <section className="ftco-section ftco-no-pt bg-light">
    	<div className="container">
    		<div className="row no-gutters">
    			<div className="col-md-12	featured-top">
    				<div className="row no-gutters">
	  					<div className="col-md-6 d-flex align-items-center">
	  						{/* estimation form */}
               

                <div className="quote-form-container request-form ftco-animate bg-primary">
        <ul className="nav nav-tabs">
       
          <li onClick={() => this.handleTabSwitch('sameDay')} className={`nav-item ${this.state.currentTab === 'sameDay' ? 'active' : ''}`}>
             Same Day
          </li>
          <li onClick={() => this.handleTabSwitch('nextDayParcel')} className={`nav-item ${this.state.currentTab === 'nextDayParcel' ? 'active' : ''}`}>
           Parcels 
          </li>
           
          {/*<li onClick={() => this.handleTabSwitch('nextDayDocument')} className={`nav-item ${this.state.currentTab === 'nextDayDocument' ? 'active' : ''}`}>
            Next Day Document
          </li> */}
        </ul>

        {this.state.currentTab === 'sameDay' && this.renderSameDayForm()}
        {this.state.currentTab === 'nextDayParcel' && this.renderNextDayParcelForm()}
        {this.state.currentTab === 'nextDayDocument' && this.renderNextDayDocumentForm()}
      </div>
                {/* <form onSubmit={(e) => this.validatePostcode(e, 'calculate-quote')} className="request-form ftco-animate bg-primary">
  <h2>Get an Instant Quote</h2>

  {this.state.stops.map((stop, index) => (
    <div key={index}>
      {stop.collectionCode !== undefined && (
        <div className="autocomplete m-0">
          <label className="label select-label">Collection Postal Code</label>
          <input
            type="text"
            value={stop.collectionCode}
            onChange={(e) => this.handleInputCollectionChange(e, index)}
            placeholder="Enter postal code"
          />
          {index > 0 && (
            <button type="button" onClick={() => this.handleDeleteCollection(index)} className="btn btn-danger delete-btn">
              <i className="fa fa-times"></i>
            </button>
          )}
          <div className='form-group m-0'>
            <label className="label select-label red">{stop.resultCollectionCode}</label>
          </div>
        </div>
      )}
      {stop.deliveryCode !== undefined && (
        <div className="autocomplete m-0">
          <label className="label select-label">Delivery Postal Code</label>
          <input
            type="text"
            value={stop.deliveryCode}
            onChange={(e) => this.handleInputDeliveryChange(e, index)}
            placeholder="Enter postal code"
          />
          {index > 0 && (
            <button type="button" onClick={() => this.handleDeleteDelivery(index)} className="btn btn-danger delete-btn">
              <i className="fa fa-times"></i>
            </button>
          )}
          <div className='form-group m-0'>
            <label className="label select-label red">{stop.resultDeliveryCode}</label>
          </div>
        </div>
      )}
    </div>
  ))}

  <button type="button" onClick={this.handleAddCollection} className="btn btn-primary addStopBtn add-collection">
    <i className="fa fa-plus"></i> Add Collection
  </button>
  <button type="button" onClick={this.handleAddDelivery} className="btn btn-primary addStopBtn">
    <i className="fa fa-plus"></i> Add Delivery
  </button>

  <div className="form-group">
    <label className="label select-label">Vehicle Type</label>
    <Select
      className='vehicles-data'
      value={this.state.selectedVehicleType}
      onChange={this.handleVehicleTypeChange}
      displayEmpty
      error={this.state.vehicleTypeError}
      helperText={this.state.vehicleTypeError && "Vehicle type is required"}
    >
      <MenuItem value="" disabled>Select Vehicle Type</MenuItem>
      {this.state.vehicleTypes?.map(vehicleType => (
        <MenuItem key={vehicleType.id} value={vehicleType.id}>{vehicleType.vehicle_type}</MenuItem>
      ))}
    </Select>
  </div>

  <div className='form-group'>
    <label className="label select-label">Email</label>
    <TextField
      className='emailEst'
      label="Email"
      variant="outlined"
      fullWidth
      margin="normal"
      value={this.state.emailEst}
      onChange={this.handleEmailEstChange}
      displayEmpty
      error={this.state.emailEstError}
      helperText={this.state.emailEstError && "Email is required"}
    />
  </div>

  <div>
    <FormControlLabel
      className='return-journey-check'
      control={
        <Checkbox
          checked={this.state.returnJourney}
          onChange={this.handleCheckboxChange}
          name="returnJourneyCheckbox"
          color="primary"
        />
      }
      label="Return Journey"
    />
  </div>

  <div className="form-group">
  <div className="row">
    {console.log("username check-----",username)}
      {username !== null && username !== ""  ? (
        <>
          <div className="col-6">
            <button type="button" onClick={(e) => this.validatePostcode(e, 'cart')} className="btn btn-secondary py-3 px-4">Add to Cart</button>
          </div>
          <div className="col-6">
            <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4" />
          </div>
        </>
      ) : (
        <div className="col-12">
          <input type="submit" value="Continue" className="btn btn-secondary py-3 px-4" />
        </div>
      )}
    </div>
  </div>

</form> */}

 
	  					</div>
	  					<div className="col-md-6 d-flex align-items-center mtop">
	  						<div className=" w-100">
	  						
                  <img id="diffobj" src={diffobj} alt=""/>
	  						</div>
                
	  					</div>
	  				</div>
            <div className='row'>
            <div className="out_of_hours_msg">
                  {this.state.out_of_hours_message}
                </div>
            </div>
				</div>
  		</div>
      </div>
    </section>



    {/* <section className="ftco-counter ftco-section img bg-light" id="section-counter">
    	<div className="container-fluid">
      <div className="row justify-content-center mb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-3">Courier Services</h2>
          </div>
        </div>
        </div>
        <div className='container'>
    		<div className="row justify-content-center">
        </div>
    	</div>
    </section>	 */}



    <section className="ftco-section">
			<div className="container-fluid">
				<div className="row justify-content-center mb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-3">Our Services</h2>
          </div>
        </div>
				<div className="row justify-content-center">
       
        {this.state.serviceList?.map(service => (
          
          <ServiceComponent
         
            key={service.id}
            id={service.id}
            serviceName={service.Service}
            serviceImg={service.icon}
          />
        ))}
				</div>
			</div>
		</section>

    <section className="ftco-counter ftco-section img bg-light" id="section-counter">
			{/* <div className="overlay"></div> */}
    	<div className="container-fluid">
      <div className="row justify-content-center mb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <h2 className="mb-3">Analytics</h2>
          </div>
        </div>
        </div>
        <div className='container'>
    		<div className="row justify-content-center">
          <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18">
              <div className="text text-border d-flex align-items-center">
                <strong className="number" data-number="90000">0</strong>
                <span>Parcels & <br/>Pallets Delivered</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18">
              <div className="text text-border d-flex align-items-center">
                <strong className="number" data-number="10000">0</strong>
                <span>Driver <br/>Network</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18">
              <div className="text text-border d-flex align-items-center">
                <strong className="number" data-number="500">0</strong>
                <span>Happy <br/>Customers</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 justify-content-center counter-wrap ftco-animate">
            <div className="block-18">
              <div className="text d-flex align-items-center">
                <strong className="number" data-number="250">0</strong>
                <span>Estimates Deliveries <br/>Per Day</span>
              </div>
            </div>
          </div>
        </div>
    	</div>
    </section>	
		

    <section className="ftco-section ftco-about">
			<div className="container">
				<div className="row no-gutters">
					
					<div className="col-md-8 wrap-about ftco-animate">
	          <div className="md-5">
	          	{/* <span className="subheading">About us</span> */}
	            <h2 className="mb-4 green-text">Why Choose Parcel Lodge?</h2>
              </div>
              <div id='welcome-text' className='col-md-11'>
	            <p>Choose Parcel Lodge for reliability and versatility in all your courier needs. Whether it’s urgent same-day deliveries, economical next-day options, expansive international shipping, or our comprehensive fulfillment and storage services, we ensure your parcels reach their destination securely and on time. Our commitment to excellence and customer satisfaction makes us the preferred choice for individuals and businesses alike. Experience the convenience and peace of mind with every shipment you entrust to us.</p>
              {/* <p><a href="#" className="btn btn-primary py-3 px-4">Search Vehicle</a></p> */}
              </div>
					</div>
					
					<div className="col-md-4 md-5 img img-2 d-flex justify-content-center align-items-center about-parcel-img" style={{
        backgroundImage: `url(${homeWelcomeImg})`
      }}>
					</div>
					
				</div>
			</div>
		</section>

		


    <section className="ftco-section testimony-section bg-light">
      
      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
          	{/* <span className="subheading">Industry</span> */}
            <h2>Compare prices from the most trusted parcel couriers in the UK</h2>
          </div>
        </div>
        </div>
        <div className="container">
        <div className="row ftco-animate">
          <div className="col-md-12">
            <div className="carousel-testimony">
			
            <Carousel
  swipeable={false}
  draggable={false}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={3000}
  keyBoardControl={true}
  customTransition="transform 0.5s ease"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <div><img src={DHL} alt="DHL"/></div>
  <div><img src={FEDEX} alt="DHL"/></div>
  <div><img src={EVRI} alt="DHL"/></div>
  <div><img src={DPD} alt="DHL"/></div>
  <div><img src={UPS} alt="DHL"/></div>
</Carousel>
         
			
			</div>
          </div>
        </div>
      </div>
    </section>


{/* 
    <section className="ftco-section testimony-section bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5">
          <div className="col-md-7 text-center heading-section ftco-animate">
          	<span className="subheading">Testimonial</span>
            <h2 className="mb-3">Happy Clients</h2>
          </div>
        </div>
        <div className="row ftco-animate">
          <div className="col-md-12">
            <div className="carousel-testimony owl-carousel ftco-owl">
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5">
                  <div className="user-img mb-2" style={{backgroundImage: "url('images/person_1.jpg')"}}>
                  </div>
                  <div className="text pt-4">
                    <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Roger Scott</p>
                    <span className="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5">
                  <div className="user-img mb-2" style={{backgroundImage: "url('images/person_2.jpg')"}}>
                  </div>
                  <div className="text pt-4">
                    <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Roger Scott</p>
                    <span className="position">Interface Designer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5">
                  <div className="user-img mb-2" style={{backgroundImage: "url('images/person_3.jpg')"}}>
                  </div>
                  <div className="text pt-4">
                    <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Roger Scott</p>
                    <span className="position">UI Designer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5">
                  <div className="user-img mb-2" style={{backgroundImage: "url('images/person_1.jpg')"}}>
                  </div>
                  <div className="text pt-4">
                    <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Roger Scott</p>
                    <span className="position">Web Developer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap rounded text-center py-4 pb-5">
                  <div className="user-img mb-2" style={{backgroundImage: "url('images/person_1.jpg')"}}>
                  </div>
                  <div className="text pt-4">
                    <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Roger Scott</p>
                    <span className="position">System Analyst</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    <section className="ftco-section">
      <div className="container-fluid">
        <div className="row justify-content-center mb-5">
          <div className="col-md-12 heading-section text-center ftco-animate">
          	{/* <span className="subheading">Industry</span> */}
            <h2>Why People Trust Us?</h2>
          </div>
        </div>
        </div>
        <div className='container'>
        <div className="row d-flex">
        {/* <IndustryComponent/> */}
        <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon} alt=""/>
                <h2 className="heading mt-4">Multi Drop Deliveries</h2>
                <div> 
                  <span>Efficient Route Planning</span>
                </div>
                <div> 
                  <span>Streamlined Operations</span>
                </div>
                <div> 
                  <span>Enhanced Customer Satisfaction</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon1} alt=""/>
                <h2 className="heading mt-4">Scheduled Booking</h2>
                <div> 
                  <span>Flexible scheduling</span>
                </div>
                <div> 
                  <span>Reliable planning </span>
                </div>
                <div> 
                  <span>Time saving convenience</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon2} alt=""/>
                <h2 className="heading mt-4">Secure payment</h2>
                <div> 
                  <span>Encrypted transactions</span>
                </div>
                <div> 
                  <span>Trusted payment gateway</span>
                </div>
                <div> 
                  <span>Fraud Prevention Measures</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon3} alt=""/>
                <h2 className="heading mt-4">24/7 customer service</h2>
                <div> 
                  <span>Always available support </span>
                </div>
                <div> 
                  <span>Instance assistance </span>
                </div>
                <div> 
                  <span>Comprehensive support</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon4} alt=""/>
                <h2 className="heading mt-4">Easy Online Booking</h2>
                <div> 
                  <span>User friendly interface</span>
                </div>
                <div> 
                  <span>seamless process</span>
                </div>
                <div> 
                  <span>Instant Confirmation</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 d-flex justify-content-center ftco-animate">
          	<div className="blog-entry">
            
              
              <div className="text pt-4 trust-sec text-center">
              <img src={calendaricon5} alt=""/>
                <h2 className="heading mt-4">Parcel Tracking</h2>
                <div> 
                  <span>We deliver all items with
care and never co-load,
damage. You can trust us to
minimising likelihood of
move time-sensitive and
delicate goods</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>	

    

    <Footer/>
    </>
  )
  </div>
  );
}

  }
export default Home;
