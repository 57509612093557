import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import './assets/parcelbooking.css';
import dummyimg from './assets/images/dummyimg.jpg'
import Header from './Header';
import Footer from './Footer';
import {vApis} from './Apis';
import sidebarBGImage from "./assets/images/bookingbanner.png";
import { ToastContainer, toast } from 'react-toastify';
import './assets/toast.css';
import InputMask from 'react-input-mask';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

// import { parsePhoneNumberFromString } from "libphonenumber-js";


class ParcelBooking extends Component {
constructor(props) {
super(props);
this.state = {
  parcel_name: "",
  first_name: "",
    last_name: "",
    company: "",
    email: localStorage.getItem("bookingemail") ? localStorage.getItem("bookingemail") : "",
    telephone_number: "",
    address: "",
    house: "",
    country: localStorage.getItem("selectedcountry")?localStorage.getItem("selectedcountry"):"GB",
    city: "",
    postcode: localStorage.getItem("deliverypostcodes")?localStorage.getItem("deliverypostcodes"):"",
    countries:[],
    isValid: null,
    validationMessage: "",
    showModal: false,
      fields: [],
      formData: {},
};
}

componentDidMount(){
  this.fetchCountries();
  this.handleOpenModal()
}

handleOpenModal = async () => {
  console.log("handleOpenModal");

  // Show the modal first
  //this.setState({ showModal: true }); 

  try {
    // Fetch dynamic fields based on carrier
    let shopData;
    
    

    const self = this.getQueryParameter('self');  // Get the 'self' query parameter
        console.log("self1111===",self)
        // Apply the condition based on the existence of 'self' in the URL
        if (self==="") {
          console.log("carrier data passed")
          shopData = JSON.stringify({
            "carrier": localStorage.getItem("carrierselected"),
            "pickup_type": "self",
            "weight": localStorage.getItem("weight"),
            "country": localStorage.getItem("country")?localStorage.getItem("country"):"UK",
            "collectionpostcodes": localStorage.getItem("collectionpostcodes")
          })
        }
        else {
          console.log("shop data passed")
          
          shopData = localStorage.getItem("selectedServicePoint")
          
        }
//     const response = await vApis.postData('parcels/fields/',shopData);
//     console.log("respon===",response)
//     // Extract the fields from the response
//     if(response?.data?.status===true){
//     const fields = response?.data?.fields;
// console.log("fields from the api----",fields)
//     // If fields are fetched successfully, update the state
//     this.setState({ fields });
    
//     console.log("Fields fetched successfully:", fields);
//     this.setState({ showModal: true });
//     }
    // else {
    //   this.notifyError(response?.data?.message)
    // }
    
  } catch (error) {
    // Log the error and optionally handle it
    console.error("There was an error fetching the fields!", error);
    
    // Optionally, you could show an error message or close the modal if the fetch fails
    this.setState({ showModal: false });
  }
};


handleCloseModal = () => {
  this.setState({ showModal: false });
};

handleInputChange = (fieldName, value) => {
  this.setState(prevState => ({
    formData: {
      ...prevState.formData,
      [fieldName]: value,
    }
    
  }));
  console.log("formdata of pickup0-===",JSON.stringify(this.state.formData));
  //console.log("formdata of pickup1-===",JSON.parse(this.state.formData));
  localStorage.setItem("pickupdata",JSON.stringify(this.state.formData))
};

getInputType = (type) => {
  if (type.includes('string<email>')) return 'email';
  if (type.includes('string<date-time>')) return 'datetime-local';
  if (type.includes('integer')) return 'number';
  return 'text'; // default type for string and other unspecified types
};

handleSave = async () => {
  this.handleCloseModal();
  this.notifySuccess('Congratulations! Pickup has been created successfully.');
  // try {
  //   // Submit the form data to the relevant API
  //   const response = await vApis.postData('parcels/save', this.state.formData);
  //   console.log('Data saved successfully:', response.data);
  //   this.handleCloseModal();
  // } catch (error) {
  //   console.error("There was an error saving the data!", error);
  // }
};

fetchCountries = async () => {
  const allCountries = await vApis.fetchData('calculator/countries-iso2/');
  const countries = allCountries?.data?.countries;
  this.setState({ countries });
}

getQueryParameter = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};


validateUKPhoneNumber = (phoneNumber) => {
  console.log("phoneNumber====",phoneNumber)
  // Regular expression to validate UK phone number format
  const pattern = /^(\+447\d{9}|07\d{9})$/;

  if (!pattern.test(phoneNumber)) {
    return { isValid: false, message: "Your phone number is not in the correct format." };
  }

  // Additional logic to detect invalid numbers
  const invalidPrefixes = ["+447555", "07555"]; // Example invalid prefixes
  if (invalidPrefixes.some(prefix => phoneNumber.startsWith(prefix))) {
    return { isValid: false, message: "Your phone number is in the correct format, but the number is invalid." };
  }

  // If the format is correct and no invalid prefixes are found
  return { isValid: true, message: "Valid number" };
};


handleBookFormNumberChange = (event) => {
  const { name, value } = event.target;
  const phoneNumber = event.target.value;
  console.log("phoneNumber====",phoneNumber)
  const isValid = this.validateUKPhoneNumber(phoneNumber);
  console.log("isValid====",isValid)
  this.setState({ telephone_number: phoneNumber, isValid });

  this.setState({ [name]: value });
};

// validatePhoneNumber = (phoneNumber) => {
//   const parsedNumber = parsePhoneNumberFromString(phoneNumber);

//   if (!parsedNumber) {
//     return { isValid: false, message: "Your phone number is not in the correct format." };
//   }

//   if (!parsedNumber.isValid()) {
//     return { isValid: false, message: "Your phone number is in the correct format, but the number is invalid." };
//   }

//   return { isValid: true, message: "Valid number" };
// };

validatePhoneNumber = (phoneNumber) => {
  // Regular expression for basic international phone number validation
  const internationalPhoneRegex = /^\+(?:\d{1,3})\s?(?:\d{1,4}[\s.-]?)?(?:\d{1,4}[\s.-]?)?(?:\d{1,9})$/;
    
    if (internationalPhoneRegex.test(phoneNumber)) {
      return { isValid: true, message: "" };
    } else {
      return { isValid: false, message: "Your phone number is not valid." };
    }
};

handleBookFormInputChange = (event) => {
  console.log("handleBookFormInputChange===")
  const { name, value } = event.target;
  
  
    this.setState({ [name]: value });
  
  
};
notifySuccess = (msg) => {
  toast.success(msg, {
    position: "top-right"
  });
};

notifyError = (msg) => {
  toast.error(msg, {
    position: "top-right"
  });
};

handleBookFormSubmit = async (event) => {
  console.log("handleBookFormSubmit")
  event.preventDefault();
  this.setState({isLoading:true})
  // Here you can use this.state to access form values
  const {
    parcel_name,
    first_name,
    last_name,
    company,
    email,
    telephone_number,
    address,
    house,
    city,
    country,
    postcode,
  } = this.state;
  const tosChecked = document.getElementById('tos1').checked;
  const prohibitedChecked = document.getElementById('prohibited').checked;

 // Validation patterns
 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
 const ukPhoneRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;

 // Validation checks for each field
 
//  if (!first_name) {
//    alert("First Name is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!last_name) {
//    alert("Last Name is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!company) {
//    alert("Company is required");
//    this.setState({ isLoading: false });
//    return;
//  }
 if (!email) {
   alert("Email is required");
   this.setState({ isLoading: false });
   return;
 } else if (!emailRegex.test(email)) {
   alert("Please enter a valid email address");
   this.setState({ isLoading: false });
   return;
 }
 if (!telephone_number) {
   alert("Telephone number is required");
   this.setState({ isLoading: false });
   return;
 } else if (!ukPhoneRegex.test(telephone_number)) {
   alert("Please enter a valid UK phone number (e.g., +447123456789 or 07123456789)");
   this.setState({ isLoading: false });
   return;
 }
//  if (!address) {
//    alert("Address is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!house) {
//    alert("House name or number is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!city) {
//    alert("City is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!country) {
//    alert("Country is required");
//    this.setState({ isLoading: false });
//    return;
//  }
//  if (!postcode) {
//    alert("Postcode is required");
//    this.setState({ isLoading: false });
//    return;
//  }

 if (!tosChecked) {
   alert("You must accept the Terms and Conditions");
   this.setState({ isLoading: false });
   return;
 }
 if (!prohibitedChecked) {
   alert("You must acknowledge the Prohibited Items");
   this.setState({ isLoading: false });
   return;
 }
 
 const parcels = JSON.parse(localStorage.getItem('parcels')) || [];
 let dataBooking = JSON.stringify({
   parcel: {
     name: parcel_name,
     first_name: first_name,
     last_name: last_name,
     company_name: company,
     address: address,
     house_number: house,
     city: city,
     postal_code: postcode,
     telephone: telephone_number,
     request_label: true,
     email: email,
     country: country,
     shipment: {
       id: Number(localStorage.getItem("selectedShippingId"))
     },
     data: {},
     to_service_point: localStorage.getItem("servicepoint"),
     price: localStorage.getItem("quoteestimation"),
     parcels: parcels.flatMap((parcel) =>
       Array.from({ length: parseInt(parcel.quantity, 10) || 1 }).map(() => ({
         name: parcel.name,
         weight: parcel.weight,
         length: parcel.length,
         width: parcel.width,
         height: parcel.height,
         value: parcel.value,
         quantity: 1 // Set each individual parcel's quantity to 1
       }))
     )
   } 
 });
 



  console.log("dataBooking===",dataBooking);
  console.log("dataBooking json===",JSON.parse(dataBooking))
  let responseBookingForm;
  console.log("checking username on booking stage-------------------",localStorage.getItem("username"))
  if(localStorage.getItem("username")!="" && localStorage.getItem("username")!=null){
    responseBookingForm = await vApis.postData('parcels/create-parcel-user/',dataBooking);
  }
  else{
    responseBookingForm = await vApis.postData('parcels/create-parcel-guest/',dataBooking);
  }
console.log("responseBookingForm=======================",responseBookingForm);

const pickupData = JSON.parse(localStorage.getItem('pickupdata')) || {};
const selectedCountry = 'GB'; // Default to 'GB'
const selectedCarrier = localStorage.getItem('carrierselected') || 'ups'; // Default carrier
const collectionPostcodes = localStorage.getItem("collectionpostcodes") || '';
const totalWeight = localStorage.getItem("weight") || '0';
const pickupEmail = localStorage.getItem("bookingemail") || '';
const bookingId = localStorage.getItem("bookingid") || 0;
const destinationCountry = localStorage.getItem("selectedcountry") || "GB";
// Create the required object and dynamically set values
const pickup_data = {
  city: pickupData.city || '',
  company_name: pickupData.company_name || '',
  name: pickupData.name || '',
  country: selectedCountry,
  email: pickupEmail,
  address: pickupData.address || '',
  address_2: pickupData.address_2 || '',
  pickup_from: pickupData.pickup_from || '',
  pickup_until: pickupData.pickup_until || '2024-08-25T17:00:00Z',
  postal_code: collectionPostcodes,
  quantity: pickupData.quantity || 0,
  telephone: pickupData.telephone || '',
  total_weight: totalWeight,
  carrier: selectedCarrier,
  booking_id: bookingId,
}


//if (pickupData.items) {
  pickup_data.items = [
    {
      "shipping_method": "011",
      "quantity": pickupData.quantity || 0,
      "destination_country": destinationCountry,
      "container_code": "01"
    }
  ];
//}
const pickupDataString = JSON.stringify(pickup_data);
var pickup_response="";
if(this.state.fields && this.state.fields.length > 0){
  pickup_response = await vApis.postData('parcels/create-pickup/', pickupDataString);
}

console.log("pickup_response===",pickup_response)
  if(responseBookingForm?.data?.status===true){
    this.notifySuccess('Congratulations! Your vehicle booking has been successfully confirmed');
    this.setState({isLoading:false})
    localStorage.setItem("bookingid",responseBookingForm?.data?.booking_id);
    localStorage.setItem("bookingemail",responseBookingForm?.data?.email);
    localStorage.setItem("sendcloud",true);
    localStorage.removeItem("outofhoursmsg")
    //localStorage.setItem("quoteestimation",responseBookingForm?.data?.total);
    // localStorage.setItem("outofhoursmsg",responseBookingForm?.data?.msg);
    // localStorage.setItem("outofhours",responseBookingForm?.data?.out_of_hour);
    // localStorage.setItem("congestion",responseBookingForm?.data?.congestion);
   window.location.href = '/pay';
  }
  else if(responseBookingForm?.data?.status===false) {
    console.log("false stat of booking")
    this.notifyError(responseBookingForm?.data?.message);
  }
  else if(pickup_response?.data?.status===false && pickup_response!=="") {
    console.log("false sta of pickup")
    this.notifyError(pickup_response?.data?.message);
  }
};

render() {
  const { telephone_number, isValid, validationMessage } = this.state;
  const { showModal, fields, formData } = this.state;
    return (
        <div className="page-container">
            <Header />
            <ToastContainer />
            
            <section className="ftco-about">
            <section className="hero-wrap hero-wrap-2 js-fullheight" style={{
        backgroundImage: `url(${sidebarBGImage})`
      }} data-stellar-background-ratio="0.5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text js-fullheight align-items-end justify-content-start">
          <div className="col-md-9 ftco-animate pb-5">
          	<p className="breadcrumbs"><span className="mr-2"><a href="/">Home <i className="ion-ios-arrow-forward"></i></a></span> <span>Booking <i className="ion-ios-arrow-forward"></i></span></p>
            <h1 className="mb-3 bread">Booking</h1>
          </div>
        </div>
      </div>
    </section>
                <div className="container">

                <section className="custom-progress-2-container md:flex md:flex-row justify-between">
            
            <div className="parcel-booking-form">
          <form onSubmit={this.handleBookFormSubmit}>

          

          {fields && fields.length > 0 ? (
            <>
            <h2>Collection Details:</h2>
  <div className="row">
    {fields
      .filter(field => {
        const self = this.getQueryParameter('self');  // Get the 'self' query parameter
        console.log("self===", self);
        // Apply the condition based on the existence of 'self' in the URL
        if (self === "") {
          return field.required;  // Only filter by required fields when 'self' is present
        } else {
          // Filter for required fields missing a value if 'self' is not present
          return field.required && (!field.hasOwnProperty('value') || field.value === "");
        }
      })
      .map(field => (
        <div key={field.name} className="col-12 col-md-6 mb-3">
          <label className="form-label">{field.name}</label>
          {this.getInputType(field.type) === 'datetime-local' ? (
            <Flatpickr
              data-enable-time
              options={{
                dateFormat: 'Y-m-d H:i',  // Specify date format
              }}
              value={this.state.formData[field.name] || ''}
              onChange={date => {
                const newDate = date[0];
                this.handleInputChange(field.name, newDate);

                // Set pickup-until to the same date if this field is pickup-from
                if (field.name === 'pickup-from') {
                  this.handleInputChange('pickup-until', newDate);
                }
              }}
              className="form-control"
              required={field.required}
            />
          ) : (
            <input
              type={this.getInputType(field.type)}
              value={this.state.formData[field.name] || ''}
              onChange={(e) => this.handleInputChange(field.name, e.target.value)}
              required={field.required}
              className="form-control"
            />
          )}
        </div>
      ))}
  </div>
  </>
)
: (
  <></>
  // <p>Loading fields...</p>
)
}


          
          <div className="custom-row">
              {/* <div className="flex flex-col">
                <label className="title font-light mb-0.5">Parcel Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" className="text-input rounded-md" name="parcel_name" value={this.state.parcel_name} onChange={this.handleBookFormInputChange}/>
              </div> */}
              
              <div className="flex flex-col">
               <h2>Delivery Details:</h2>
              </div> 

            </div>
              <div className="sm:flex custom-row">
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">First Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" required={true} className="text-input rounded-md" name="first_name" value={this.state.first_name} onChange={this.handleBookFormInputChange}/>
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
                <label className="title font-light mb-0.5">Last Name <span className="text-red-500">*</span>
                </label>
                <input type="text" maxLength="20" required className="text-input rounded-md" autoComplete="off" name="last_name" value={this.state.last_name} onChange={this.handleBookFormInputChange}/>
              </div>
            </div>
            <div className="sm:flex custom-row">
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">Company </label>
                <input type="text" maxLength="60" required className="text-input rounded-md" autoComplete="off" name="company" value={this.state.company} onChange={this.handleBookFormInputChange}/>
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
                <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span>
                </label>
                

                <input type="email" maxLength="255"  required className="text-input rounded-md" autoComplete="off" name="email" value={this.state.email} onChange={this.handleBookFormInputChange}/>
              </div>
            </div>
            <div className="sm:flex custom-row">
              {/* <div className="flex flex-col w-full sm:w-1/2 sm:mr-3 mb-0 md:mb-0">
                <label className="title font-light mb-0.5">Email <span className="text-red-500">*</span>
                </label>
                <input type="email" maxLength="255" className="text-input rounded-md" autoComplete="off" name="email" value={this.state.email} onChange={this.handleBookFormInputChangeFields}/>
              </div> */}
              <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
                <label className="title font-light mb-0.5">Telephone number <span className="text-red-500">*</span>
                </label>

                <input
          type="tel"
          id="phone"
          className="text-input rounded-md"
          name="telephone_number"
          value={telephone_number}
          required={true}
          onChange={this.handleBookFormInputChange}
          placeholder="Enter international phone number"
        />
        
        {/* {isValid !== null && <p style={{ color: isValid ? "green" : "red" }}>{validationMessage}</p>} */}
                {/* <input type="tel" maxLength="20" min="0" className="text-input rounded-md" autoComplete="off" name="telephone_number" value={this.state.telephone_number} onChange={this.handleBookFormInputChange}/> */}
              </div>
              <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">Address<span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="32" className="text-input rounded-md" placeholder="" required autoComplete="off" name="address" value={this.state.address} onChange={this.handleBookFormInputChange}/>
        </div>
</div>
<div className="sm:flex custom-row">
              
        <div className="flex flex-col sm:w-1/2 sm:mr-3 mb-4 md:mb-0">
          <label className="title font-light mb-0.5">House</label>
          <input type="text" maxLength="32" className="text-input rounded-md" placeholder="" required autoComplete="off" name="house" value={this.state.house} onChange={this.handleBookFormInputChange}/>
        </div>
        {/* <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">Country <span className="text-red-500">*</span>
          </label>
          <select
                        className="text-input rounded-md"
                        name="country"
                        value={this.state.country}
                        onChange={this.handleBookFormInputChange}
                        required
                      >
                        <option value="" disabled className='country-select-color'>Select Country</option>
                        {this.state.countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
          
        </div> */}
        <div className="flex flex-col sm:w-1/2 sm:ml-3">
          <label className="title font-light mb-0.5">City <span className="text-red-500">*</span>
          </label>
          <input type="text" maxLength="32" className="text-input rounded-md" required placeholder="" autoComplete="off" name="city" value={this.state.city} onChange={this.handleBookFormInputChange}/>
        </div>
            </div>
          
            <div>
          {/* <button type="button" className='add-pickup-btn mb-4' onClick={this.handleOpenModal}>Add Pickup</button>
          {console.log("show modal====",showModal)} */}
          
        </div>




          <div className="flex flex-col">
                <div>
                  <input type="checkbox" className="mr-2" id="prohibited" />
                  <label htmlFor="prohibited" id="tnc-label"> Non Alcohalic Items
                    <span className="text-red-500"> *</span>
                  </label>
                </div>
          
              </div>
          
                <div className="flex flex-col">
                <div>
                  <input type="checkbox" className="mr-2" id="tos1" />
                  <label htmlFor="tos1" id="tnc-label">I have read and accepted the <a href="/terms" target="_blank" className="text-green-primary text-base">T&amp;Cs</a>
                    <span className="text-red-500"> *</span>
                  </label>
                </div>
          
              </div>
              <div className="payment-box">
                
                <div className="mt-4 mb-4">
                  
                  <button className="custom-green-button default-bg-color" type='submit'>Book Now</button>
                 
                </div>
              </div>
              </form>
           </div>
           </section>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default ParcelBooking;
        