
import React, { Component } from 'react';
import './assets/animate.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import sidebarBGImage from "./assets/images/bg_1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";
import close from "./assets/images/close.png";
import logoBlack from "./assets/images/LOGO-black.png";
import {vApis} from './Apis';
import CircularProgress from '@material-ui/core/CircularProgress';
import PriceCalculator from './PriceCalculator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import eventEmitter from './Eventemitter';

//function App() {
  class Header extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isMenuOpen: false,
        showLoginPopup: false,
        showSignupPopup: false,
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        errors: {
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
        },
        registrationSuccess: false,

        usernameLogin: '',
      passwordLogin: '',
      errorsLogin: {
        username: '',
        password: '',
      },
      loggedIn: localStorage.getItem('username') ? true : false,
      isOpen: false,
      industriesList:[],
      serviceList: [],
      theLoading: false,
      signuperror: false,
      showsignuperror: "",
      newsignuperror: "",
      loginerror: false,
      loginerrormsg: "",
      showCalculator: false,
      cartCounter: 0
      };
      this.toggleMenu = this.toggleMenu.bind(this);
      this.toggleDropdown = this.toggleDropdown.bind(this);
      this.getFormattedMessages = this.getFormattedMessages.bind(this);
    }
  
    toggleMenu() {
      console.log("toggle menu")
      this.setState(prevState => ({
        isMenuOpen: !prevState.isMenuOpen
      }));
    }

    toggleDropdown = () => {
      console.log("toggle drop down of user----")
      this.setState(prevState => ({
        isOpen: !prevState.isOpen
      }));
      console.log("isopen state===", this.state.isOpen)
    };
    handleNavLinkClick = () => {
      this.setState({ isMenuOpen: false });
    };
  
    handleLogout = async() => {
      // Implement your logout logic here
      //console.log("Logged out!");
      this.setState({loggedIn: false});
      localStorage.removeItem('username');
      const responseReg = await vApis.postData('users/logout/', {});

      setTimeout(() => {
        window.location.href="/"
        },300)
    };

    handleLoginChange = (e) => {
      
      const { name, value } = e.target;
      //console.log("name===",name)
      //console.log("value===",value)
      this.setState({ [name]: value });
    };
  
    handleLoginSubmit = (e) => {
      e.preventDefault();
      this.setState({theLoading:true})
      const { usernameLogin, passwordLogin } = this.state;
      const errorsLogin = {};
  
      // Validate email
      if (!usernameLogin.trim()) {
        errorsLogin.username = 'Username is required';
      }
  
      // Validate password
      if (!passwordLogin.trim()) {
        errorsLogin.password = 'Password is required';
      }
  
      this.setState({ errorsLogin });
  
      // If there are no errors, submit the form data to the login API
      if (Object.keys(errorsLogin).length === 0) {
        // Call login API with email and password
        this.loginUser(usernameLogin, passwordLogin);
      }
    };
  
    loginUser = async (username, password) => {
      // Simulate login API call
      console.log('Logging in with:', { username, password });
      // You can replace this with your actual login API call

      let reg_data = JSON.stringify({
        username: username,
        password: password,
      })
      console.log("est_data===",reg_data)
      const responseReg = await vApis.postData('users/login/',reg_data);
      console.log("response of login===",responseReg.data);
      
      if(responseReg!=null){
      //this.setState({ registrationSuccess: true });
      //setTimeout(() => {
        if(responseReg.data.status===true){
          this.setState({ showLoginPopup: false, loggedIn: true});
          this.setState({theLoading:false,loginerror:false});
          localStorage.setItem("username",responseReg.data.username);
      eventEmitter.emit('login', responseReg.data.username);
      eventEmitter.emit('loginEmail', responseReg.data.email);
      localStorage.setItem("bookingemail",responseReg.data.email)
      localStorage.setItem("token",responseReg.data.token);
      setTimeout(() => {
      window.location.href="/"
      },300)
        }
        else  {
          this.setState({ showLoginPopup: true, loggedIn: false});
          this.setState({theLoading:false, loginerror: true, loginerrormsg: responseReg.data.message})
        }
      
      //}, 2000);
      }
      
    };


    handleRegFormChange = (e) => {
      const { name, value } = e.target;
      this.setState({ [name]: value });
    };
  
    handleRegSubmit = async (e) => {
      e.preventDefault();
      this.setState({theLoading:true})
      const { username, email, password, confirmPassword } = this.state;
      const errors = {};
  
      if (username.trim() === '') {
        errors.username = 'Username is required';
      }
      if (email.trim() === '') {
        errors.email = 'Email is required';
      }
      if (password.trim() === '') {
        errors.password = 'Password is required';
      }
      if (confirmPassword.trim() === '') {
        errors.confirmPassword = 'Confirm Password is required';
      }
  
      this.setState({ errors });
  
      // If there are no errors, submit the form or call API
      if (Object.keys(errors).length === 0) {
        // Call your API or submit the form
        console.log('Form submitted:', { username, email, password, confirmPassword });
        let reg_data = JSON.stringify({
          username: username,
          email: email,
          password1: password,
          password2: confirmPassword
        })
        console.log("est_data===",reg_data)
        const responseReg = await vApis.postData('users/signup/',reg_data);
        console.log("response===",responseReg.data);
        if(responseReg.data.status===true){
          console.log("reg sucessssssssssssssssssssssssssss")
          this.setState({ registrationSuccess: true, theLoading:false, showSignupPopup:true});
        //setTimeout(() => {
        //this.setState({ showSignupPopup: false, registrationSuccess: false });
        //}, 2000);
        }
        else {
          this.setState({ theLoading:false, signuperror: true, showsignuperror:responseReg.data.message});
          console.log("responseReg.data.message====",responseReg.data.message);
          this.getFormattedMessages(responseReg.data.message)
        }
      }
    };

    getFormattedMessages = (messages) => {
      const { showsignuperror } = this.state;
console.log("data===0",messages)
      
const formattedMessages = Object.values(messages)
      .flat()
      .map(message => message.replace(/^.*?:\s*/, ''))
      .join('\n');
this.setState({ newsignuperror: formattedMessages });
    };
    showLoginPopup = () => {
      this.setState({ showLoginPopup: true });
      this.setState({ showSignupPopup: false });
    };
  
    showSignupPopup = () => {
      this.setState({ showSignupPopup: true });
      this.setState({ showLoginPopup: false });
    };

    hideLoginPopup = () => {
      this.setState({ showLoginPopup: false });
    }
    hideSignupPopup = () => {
      this.setState({ showSignupPopup: false });
    }

    componentDidMount() {
        const storedCartCounter = localStorage.getItem("cartcounter");
        if (storedCartCounter && parseInt(storedCartCounter) > 0) {
          this.setState({ cartCounter: parseInt(storedCartCounter) });
        }
        window.addEventListener('scroll', this.handleScroll);
        this.fetchIndustries();
        this.fetchServices();

        
    }
    fetchServices = async () => {
      //console.log("fetchServices=====")
        const allServices = await vApis.fetchData('calculator/services/');
        //console.log("all allServices====",allServices?.data?.services_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const serviceList = allServices?.data?.services_list;
        //console.log("serviceList===",serviceList);
        this.setState({ serviceList })
    };
    fetchIndustries = async () => {
      //console.log("fetchIndustries=====")
        const allIndus = await vApis.fetchData('calculator/industries/');
        //console.log("all indus====",allIndus?.data?.industries_list);
        // const vehicleTypes = allVehicles.data.vehicles_list.map(item => item.vehicle_type);
        const industriesList = allIndus?.data?.industries_list;
        //console.log("industriesList===",industriesList);
        this.setState({ industriesList })
    };
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
      }
      handleScroll = () => {

        const sections = document.querySelectorAll('.ftco-section');
        sections.forEach(section => {
          const sectionTop = section.getBoundingClientRect().top;
          const windowHeight = window.innerHeight;
  
          if (sectionTop < windowHeight) {
            section.classList.add('show');
            if (section.id === "section-counter") {
              // Run a specific function
              this.runCounters();
            }
  
          } else {
            section.classList.remove('show');
          }
        });
      }
      runCounters=()=> {
        console.log("run counters")
        setTimeout(() => {
          const counters = document.querySelectorAll('.number');
  
        counters.forEach(counter => {
          const updateCount = () => {
            const target = +counter.getAttribute('data-number');
            const count = +counter.innerText;
    
            const speed = 200; // Change speed as needed
            const inc = target / speed;
    
            if (count < target) {
              counter.innerText =  Math.ceil(count + inc); // Append "+" sign
              setTimeout(updateCount, 1);
            } else {
              counter.innerText =  target; // Append "+" sign
            }
          };
    
          updateCount();
        });
        }, 1000);
        
      }

      toggleCalculator = () => {
        // this.setState(prevState => ({
        //   showCalculator: !prevState.showCalculator
        // }));
        window.location.href = '/?scrollTo=the-quote-calculator';
      };

    render() {

      const { registrationSuccess,errors } = this.state; 
      const { usernameLogin, passwordLogin, errorsLogin, theLoading } = this.state;

    return (
      <>

      <div>
      <div class="animated-button1" onClick={this.toggleCalculator}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Get a Quote
        </div>
        {/* <button  onClick={this.toggleCalculator}>Open Price Calculator</button> */}
        {this.state.showCalculator && (
          <div className="loader-overlay">
            <PriceCalculator />
            <span className="close-btn"><FontAwesomeIcon  icon={faTimes} size="2x" style={{ fontWeight: 'lighter' }} onClick={this.toggleCalculator}/></span>
            {/* <button className="close-btn" onClick={this.toggleCalculator}>Close</button> */}
          </div>
        )}
      </div>
      {theLoading &&
      <div className="loader-overlay">
      <CircularProgress className="loader" />
      </div>
      }
      <div className="popup-container">
        {/* <button onClick={this.showLoginPopup}>Login</button>
        <button onClick={this.showSignupPopup}>Signup</button> */}

        {this.state.showLoginPopup && (
          <div className="container-form">
                    <img className="close-form" src={close} onClick={this.hideLoginPopup} alt="close-form"/>
                    <div className="login form">
                      
                      <header>Login</header>
                      <form onSubmit={this.handleLoginSubmit}>
          <input
            type="text"
            name="usernameLogin"
            placeholder="Enter your username"
            value={usernameLogin}
            onChange={this.handleLoginChange}
            className={errors.username ? 'error' : ''}
          />
          {errorsLogin.username && <div className="error-msg">{errorsLogin.username}</div>}
          <input
            type="password"
            name="passwordLogin"
            placeholder="Enter your password"
            value={passwordLogin}
            onChange={this.handleLoginChange}
            className={errors.password ? 'error' : ''}
          />
          {errorsLogin.password && <div className="error-msg">{errorsLogin.password}</div>}
          {/* <a href="/">Forgot password?</a> */}
          <input type="submit" className="button" value="Login" />
        </form>
                      <div className="signup">
                      <span>{this.state.loginerror && <>
                        <pre className='red serr'>{this.state.loginerrormsg}</pre> 
                        <br></br></>}
                      </span>
                        <span className="signup">Don't have an account?
                        <label for="check" onClick={this.showSignupPopup}>Signup</label>
                        </span>
                      </div>
                    </div>
            {/* <button onClick={this.showLoginPopup}>Close</button> */}
          </div>
        )}

        {this.state.showSignupPopup && (
          <div className="container-form">
           <img className="close-form" src={close} onClick={this.hideSignupPopup} alt="close-form"/>
            <div className="registration form">
            
              
              {registrationSuccess ? (
              <div className="success-msg">Thank you for signing up!

              To complete your registration, please check your email and click the verification link we've sent you. Once verified, you'll have full access to our platform.</div>
            ) : (
              <>
              <header>Signup</header>
              <form onSubmit={this.handleRegSubmit}>
              <input
                type="text"
                name="username"
                placeholder="Enter your username"
                value={this.state.username}
                onChange={this.handleRegFormChange}
                className={errors.username ? 'error' : ''}
              />
              {errors.username && <div className="error-msg">{errors.username}</div>}
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={this.state.email}
                onChange={this.handleRegFormChange}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <div className="error-msg">{errors.email}</div>}
              <input
                type="password"
                name="password"
                placeholder="Create a password"
                value={this.state.password}
                onChange={this.handleRegFormChange}
                className={errors.password ? 'error' : ''}
              />
              {errors.password && <div className="error-msg">{errors.password}</div>}
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={this.state.confirmPassword}
                onChange={this.handleRegFormChange}
                className={errors.confirmPassword ? 'error' : ''}
              />
              {errors.confirmPassword && <div className="error-msg">{errors.confirmPassword}</div>}
              <input type="submit" className="button" value="Signup" />
            </form>

              <div className="signup">
                <span>{this.state.signuperror && <>
                 <pre className='red serr'>{this.state.newsignuperror}</pre> 
                <br></br></>}
                </span>
                <span className="signup">Already have an account?
                <label for="check" onClick={this.showLoginPopup}>Login</label>
                </span>
              </div>
            </>
            )}
              
            </div>
            
          </div>
        )}
      </div>
      <div className="top-header bg-black text-light py-1">
          <div className="container d-flex justify-content-between">
            <div className="contact-info">
              {/* <span className="mr-3"><i className="fa fa-phone mr-1"></i> +123 456 7890</span>
              <span><i className="fa fa-envelope mr-1"></i> info@example.com</span> */}
            </div>
            <div className="social-links">
            <span className="mr-3"><i className="fa fa-phone mr-1"></i> +123 456 7890</span>
              <span><i className="fa fa-envelope mr-1"></i> sales@gravityspeedexpress.com</span>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light menu-for-mobile" id="ftco-navbar">
          
	    <div className="container">
	      <a className="navbar-brand logo" href="/"><img src={logoBlack} alt="logo"/></a>
	      
        <button className="navbar-toggler" type="button" onClick={this.toggleMenu}>
            <span className="oi oi-menu"></span> Menu
          </button>

          {/* <div className={`collapse navbar-collapse ${this.state.isMenuOpen ? 'show' : ''}`} id="ftco-nav"> */}
          <div className={`collapse navbar-collapse ${this.state.isMenuOpen ? 'show' : 'hide'}`} id="ftco-nav">
	        <ul className="navbar-nav ml-auto">
	         
	          

            <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/services" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Services</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {this.state.serviceList.map(ser => (
                                <a key={ser.id} className="dropdown-item" href={`/service/?id=${ser.id}`}>{ser.Service}</a>
                            ))}
            </div>
            </li>
			
			
			<li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/services" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Courier Services</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            
                                <a className="dropdown-item" href={"/service/?id=2"}>Same Day Delivery</a>
								<a className="dropdown-item" href={"/vehicles"}>Vehicles</a>
								
                            
            </div>
            </li>

			  
			  
			  <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Industries</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            {this.state.industriesList.map(industry => (
                                <a key={industry.id} className="dropdown-item" href={`/industry/?id=${industry.id}`}>{industry.Industry}</a>
                            ))}
							<a className="dropdown-item" href={"/service/?id=3"}>Next Day Delivery</a>
            </div>
            </li>
			
			
			<li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Tools</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            
							<a className="dropdown-item" href="/">Length & Girth Calculator </a>
                    <a className="dropdown-item" href="/">Volumetric Weight Calculator </a>
                    <a className="dropdown-item" href="/">Prices & Surcharges </a>
                    <a className="dropdown-item" href="/">Package & Guidelines </a>
                   <a className="dropdown-item" href="/">Prohibited Items</a>
            </div>
            </li>
			
			<li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Carriers</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            
							<a className="dropdown-item" href="/dhl">DHL </a>
                    <a className="dropdown-item" href="/evri">EVRI </a>
                    <a className="dropdown-item" href="/dpd">DPD </a>
                    <a className="dropdown-item" href="/upd">UPS </a>
                   <a className="dropdown-item" href="/royalmail">Royalmail </a>
                    <a className="dropdown-item" href="/fedex">Fedex</a>

                    <a className="dropdown-item" href="/parcelforce">Parcelforce </a>
                    <a className="dropdown-item" href="/pallet">Palletways </a>
            </div>
            </li>
			
		
			  
	         <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            How it works</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            
							<a href="/about" className="dropdown-item">About Us</a>
							<a href="/" className="dropdown-item">Apply for a business account</a>
            </div>
            </li>
			
			
			<li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/industries" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
            Help & Support</a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            
							<a href="/faq" className="dropdown-item">FAQ</a>
            </div>
            </li>
			
	          
	         
	         
           
            {!this.state.loggedIn && <>
            <li id="login-btn" onClick={this.showLoginPopup}>Login</li>
            <li id="signup-btn" onClick={this.showSignupPopup}>Signup</li>
            </>
            }
            
            {this.state.loggedIn &&  <>

              <div className="user-dropdown">
                <div className="username" onClick={this.toggleDropdown}>
                  <span>{localStorage.getItem("username")}</span>
                  <span className={`dropdown-arrow ${this.state.isOpen ? 'open' : ''}`}>&#9660;</span>
                </div>
                
                {this.state.isOpen && (
                  <div className="dropdown-menu-logout">
                    
                      <span onClick={this.handleLogout}>Logout</span>
                    
                  </div>
                )}
              </div>
            </>
            }
           
            
	        </ul>
	      </div>
	    </div>
	  </nav>

    <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light menu-for-desktop" id="ftco-navbar">
  <div className="container">
    <a className="navbar-brand logo" href="/"><img src={logoBlack} alt="logo" /></a>

    <div className='collapse navbar-collapse' id="ftco-nav">
      <ul className="navbar-nav me-auto explore-btn">
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="menuDropdown" role="button">
            Explore
          </a>

          <div className="dropdown-menu" aria-labelledby="menuDropdown">
            <div className="dropdown-grid">
              <div className="dropdown-column">
                <h6 className="dropdown-header">Services</h6>
                <ul className="list-unstyled">
                  {this.state.serviceList
                    .filter(ser => ser.id !== 2 && ser.id !== 3) // Filter out services with id 2 and 3
                    .map(ser => (
                      <li key={ser.id}>
                        <a className="dropdown-item" href={`/service/?id=${ser.id}`}>
                          {ser.Service}
                        </a>
                      </li>
                    ))}
                    
                </ul>
              </div>

              <div className="dropdown-column">
                <h6 className="dropdown-header">Courier Services</h6>
                <ul className="list-unstyled pl-2 ">
                  <li><a className="dropdown-item" href={"/service/?id=2"}>Same Day Delivery</a></li>
                  <ul className="list-unstyled">
                  <li><a href="/vehicles" className="dropdown-item">Vehicles</a></li>
                </ul>
                  
                </ul>
                <h6 className="dropdown-header  pl-3 mt-2">Industries</h6>
                <ul className="list-unstyled  pl-2 pb-2">
                  {this.state.industriesList.map(industry => (
                    <li key={industry.id}><a className="dropdown-item" href={`/industry/?id=${industry.id}`}>{industry.Industry}</a></li>
                  ))}
                </ul>
                <li className=' pl-2'><a className="dropdown-item  pl-2" href={"/service/?id=3"}>Next Day Delivery</a></li>
              </div>

              {/* <div className="dropdown-column">
                <h6 className="dropdown-header">Industries</h6>
                <ul className="list-unstyled">
                  {this.state.industriesList.map(industry => (
                    <li key={industry.id}><a className="dropdown-item" href={`/industry/?id=${industry.id}`}>{industry.Industry}</a></li>
                  ))}
                </ul>
              </div> */}

<div className="dropdown-column">
                <h6 className="dropdown-header">Tools</h6>
                <ul className="list-unstyled">
                
                    <li><a className="dropdown-item" href="/">Length & Girth Calculator </a></li>
                    <li><a className="dropdown-item" href="/">Volumetric Weight Calculator </a></li>
                    <li><a className="dropdown-item" href="/">Prices & Surcharges </a></li>
                    <li><a className="dropdown-item" href="/">Package & Guidelines </a></li>
                    <li><a className="dropdown-item" href="/">Customs </a></li>
                    <li><a className="dropdown-item" href="/">Prohibited Items</a></li>
                </ul>
              </div>

              <div className="dropdown-column">
                <h6 className="dropdown-header">Carriers</h6>
                <ul className="list-unstyled">
                
                    <li><a className="dropdown-item" href="/dhl">DHL </a></li>
                    <li><a className="dropdown-item" href="/evri">EVRI </a></li>
                    <li><a className="dropdown-item" href="/dpd">DPD </a></li>
                    <li><a className="dropdown-item" href="/upd">UPS </a></li>
                    <li><a className="dropdown-item" href="/royalmail">Royalmail </a></li>
                    <li><a className="dropdown-item" href="/fedex">Fedex</a></li>

                    <li><a className="dropdown-item" href="/parcelforce">Parcelforce </a></li>
                    <li><a className="dropdown-item" href="/pallet">Palletways </a></li>
                    {/* <li><a className="dropdown-item" href="/">APC</a></li> */}
                    
                </ul>
              </div>

              {/* <div className="dropdown-column">
                <h6 className="dropdown-header">Vehicles</h6>
                <ul className="list-unstyled">
                  <li><a href="/vehicles" className="dropdown-item">Vehicles</a></li>
                </ul>
              </div> */}

              <div className="dropdown-column">
                <h6 className="dropdown-header">How it works</h6>
                <ul className="list-unstyled">
                  <li><a href="/about" className="dropdown-item">About Us</a></li>
                  <li><a href="/" className="dropdown-item">Apply for a business account</a></li>
                </ul>
              </div>

              <div className="dropdown-column">
                <h6 className="dropdown-header">Help & Support</h6>
                <ul className="list-unstyled">
                  <li><a href="/faq" className="dropdown-item">FAQ</a></li>
                </ul>
              </div>

            </div>
          </div>
        </li>

        {this.state.loggedIn && (
          <li className="nav-item">
            <a className="nav-link" href="/orders" id="orderMenu" role="button">
              Orders
            </a>
          </li>
        )}
      </ul>

      <div className="remaining-menu-items ms-auto">
        <ul className="navbar-nav">
          {!this.state.loggedIn && <>
            <li className="nav-item" id="login-btn" onClick={this.showLoginPopup}>Login</li>
            <li className="nav-item" id="signup-btn" onClick={this.showSignupPopup}>Signup</li>
          </>}

          {this.state.loggedIn && <>
            <div className="user-dropdown">
              <div className="username" onClick={this.toggleDropdown}>
                <span>{localStorage.getItem("username")}</span>
                <span className={`dropdown-arrow ${this.state.isOpen ? 'open' : ''}`}>&#9660;</span>
              </div>

              {this.state.isOpen && (
                <div className="dropdown-menu-logout">
                  <span onClick={this.handleLogout}>Logout</span>
                </div>
              )}
            </div>

            {this.state.cartCounter > 0 && 
              <div id='cart-opt'>
                <a href="/carts">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#07b110" className="bi bi-cart" viewBox="0 0 16 16">
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
                  </svg>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger cart-counter">
                    {this.state.cartCounter}
                  </span>
                </a>
              </div>
            }
          </>}
        </ul>
      </div>
    </div>
  </div>
</nav>

    </>
    )
    }
}
export default Header;