import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';

import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';

import svgBanner from "./assets/images/Park.jpg";

import logistics1 from "./assets/images/logistics1.jpg";
import storageBanner from "./assets/images/storage1.jpg";
import homeWelcomeImg from "./assets/images/about.png";
import logo from "./assets/images/LOGO-white.png";

import calendaricon from "./assets/images/calendar_icon.png";
import diffobj from "./assets/images/diffobj.png";


import time from "./assets/images/time.png";
import van from "./assets/images/van.png";
import aero from "./assets/images/aero.png";
import box from "./assets/images/box.png";
import boy from "./assets/images/boy.png";
import storage from "./assets/images/storage.png";

import bannervan from "./assets/images/van(1).png";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {vApis} from './Apis';
import  MapContainer  from './MapContainer';
import { FaArrowRight } from 'react-icons/fa';
import ServiceComponent from './ServicesSection';
import Header from './Header';
import Footer from './Footer';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import industryImg from "./assets/images/image_6.jpg";
import IndustryBanner from './assets/images/contact-banner.png';
import ContactForm from './ContactForm';
// import serviceImg from '';

class ThankYou extends Component {
    constructor(props) {
      super(props);
      this.state = {
        serviceData: [],
        
      }
    }

    componentDidMount() {

      if (localStorage.getItem("sendcloud") !== null && localStorage.getItem("sendcloud") === "false" && localStorage.getItem("cartid")!==null) {
        this.deleteCartItem();
    }
    
      
     
      }

     
      deleteCartItem = async () => {
        
        let obj = JSON.stringify({
            cart_id:  localStorage.getItem("cartid")
        })
       let delCartResp = await vApis.deleteData('payments/cart/',obj);
       console.log("delCartResp==",delCartResp);

    }

    printLabel = async () => {
        
      let parcelId = 0;
     let delCartResp = await vApis.postData('parcels/PrintLabel/',parcelId);
     console.log("delCartResp==",delCartResp);

  }


    render() {
        const { serviceData } = this.state;
  return (
    <div id="thankyou-page">
        <Header/>



        <section className="ftco-about">
			<div className="container">
				<div className="row no-gutters">
					
					<div className="col-md-12 wrap-about">
          <div class="container mt-10">
        <div class="row">
            <div class="col-12">
                <h1 class="display-4">Thank you for your payment.</h1>
                <p>We are excited to have you with us. Thank you for choosing Parcel Lodge for your needs. We are currently processing your booking and will get back to you shortly with more details.</p>
            </div>

            <div class="col-12">
                
                <button type="button" class="btn btn-secondary py-3 px-4"  onClick={this.printLabel}>Print Label</button>
            </div>
        </div>

    </div>
					</div>
					
					
					
				</div>
			</div>
		</section>


   


<Footer/>


    </div>
  );
    }
}

export default ThankYou;
